import { VehicleInspectionList } from "../../entities/routing/VehicleInspectionList";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";


export const VehicleInspectionListService = {
    get: (id: string) => WebClient.Get<VehicleInspectionList>(`${apiServer}/api/inspections/lists/${id}`),
    getAll: () => WebClient.Get<VehicleInspectionList[]>(`${apiServer}/api/inspections/lists`),
    create: (name: string) => WebClient.Post.Validated<VehicleInspectionList>(`${apiServer}/api/inspections/lists`, { name }),
    addItem: (request: { listId: string, name: string }) => WebClient.Post.Validated<VehicleInspectionList>(`${apiServer}/api/inspections/lists/${request.listId}/items`, request),
    removeItem: (request: {listId: string, itemId: number}) => WebClient.Delete<VehicleInspectionList>(`${apiServer}/api/inspections/lists/${request.listId}/items/${request.itemId}`),
    delete: (id: string) => WebClient.Delete<VehicleInspectionList>(`${apiServer}/api/inspections/lists/${id}`)
};