import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { ServerErrorView } from "../../../components/ServerErrorView";
import { GeneralLedgerAccount } from "../../../entities/accounting/GeneralLedgerAccount";
import { routes } from "../../../routes";
import { LedgerAccountService } from "../../../services/accounting/LedgerAccountsService";
import { ServerError } from "../../../services/server/WebClient";
import { LedgerAccountDetailView } from "./LedgerAccountDetailView";
import { LedgerAccountListView } from "./LedgerAccountListView";

export function LedgerAccountPage() {
	const [ledgerAccounts, setLedgerAccounts] = React.useState<GeneralLedgerAccount[]>();
	const [serverError, setServerError] = React.useState<ServerError>();

	useEffect(() => {
		async function fetchLedgerAccounts() {
			const result = await LedgerAccountService.getAll();
			if (result.success) {
				setLedgerAccounts(result.data);
			} else {
				setServerError(result);
			}
		}
		fetchLedgerAccounts();
	}, []);

	if (serverError) return <ServerErrorView serverError={serverError} />;

	if (!ledgerAccounts) return <OverlayLoadingScreen />;

	return (
		<LedgerAccountPageProvider ledgerAccounts={ledgerAccounts} setLedgerAccounts={setLedgerAccounts}>
			<LedgerAccountPageRouter />
		</LedgerAccountPageProvider>
	);
}

interface LedgerAccountPageContext {
	ledgerAccounts: GeneralLedgerAccount[];
	selectedLedgerAccount: GeneralLedgerAccount | undefined;
	addLedgerAccount: (ledgerAccount: GeneralLedgerAccount) => void;
	updateLedgerAccount: (ledgerAccount: GeneralLedgerAccount) => void;
}

const LedgerAccountPageReactContext = React.createContext<LedgerAccountPageContext>({} as LedgerAccountPageContext);
export const useLedgerAccountPage = () => React.useContext(LedgerAccountPageReactContext);

function LedgerAccountPageProvider(props: PropsWithChildren<{ ledgerAccounts: GeneralLedgerAccount[]; setLedgerAccounts: (ledgerAccounts: GeneralLedgerAccount[]) => void }>) {
	const { ledgerAccounts, setLedgerAccounts } = props;
	const params = useParams<{ generalLedgerAccountId: string | undefined }>();
	const ledgerAccountId = isNaN(Number(params.generalLedgerAccountId)) ? undefined : Number(params.generalLedgerAccountId);

	const selectedLedgerAccount = useMemo(() => ledgerAccounts.find((u) => u.id === ledgerAccountId), [ledgerAccounts, ledgerAccountId]);

	const addLedgerAccount = (ledgerAccount: GeneralLedgerAccount) => setLedgerAccounts([...ledgerAccounts, ledgerAccount]);
	const updateLedgerAccount = (ledgerAccount: GeneralLedgerAccount) => setLedgerAccounts(ledgerAccounts.map((u) => (u.id === ledgerAccount.id ? ledgerAccount : u)));

	return (
		<LedgerAccountPageReactContext.Provider value={{ ledgerAccounts, selectedLedgerAccount, addLedgerAccount, updateLedgerAccount }}>
			{props.children}
		</LedgerAccountPageReactContext.Provider>
	);
}

function LedgerAccountPageRouter() {
	const { selectedLedgerAccount } = useLedgerAccountPage();

	return (
		<Switch>
			<Route exact path={routes.business.generalLedgerAccountsPage}>
				<LedgerAccountListView />
			</Route>
			{selectedLedgerAccount && (
				<Route exact path={routes.business.generalLedgerAccountDetailPage}>
					<LedgerAccountDetailView account={selectedLedgerAccount} />
				</Route>
			)}
		</Switch>
	);
}
