import { Truck, TruckType } from "../../entities/routing/Truck";
import { Modify } from "../../utility/modifyType";
import { OptionalValue, toOptional } from "../../utility/OptionalValue";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

interface CreateTruckRequest {
	name: string;
	type: TruckType;
	capacity: number | null;
	licensePlate?: string | null;
	vin?: string | null;
	make?: string | null;
	model?: string | null;
	year?: string | null;
	color?: string | null;
}

interface UpdateTruckRequest {
	id: string;
	name?: string;
	inService?: boolean;
	capacity?: number | null;
	licensePlate?: string | null;
	vin?: string | null;
	make?: string | null;
	model?: string | null;
	year?: string | null;
	color?: string | null;
}

type ServerUpdateTruckRequest = Modify<
	UpdateTruckRequest,
	{
		licensePlate?: OptionalValue<string | null>;
		vin?: OptionalValue<string | null>;
		make?: OptionalValue<string | null>;
		model?: OptionalValue<string | null>;
		year?: OptionalValue<string | null>;
		color?: OptionalValue<string | null>;
	}
>;

function serializeUpdateRequest(request: UpdateTruckRequest): ServerUpdateTruckRequest {
	return {
		...request,
		licensePlate: toOptional(request.licensePlate),
		vin: toOptional(request.vin),
		make: toOptional(request.make),
		model: toOptional(request.model),
		year: toOptional(request.year),
		color: toOptional(request.color),
	};
}

export const TruckService = {
	get: (id: string) => WebClient.Get<Truck>(`${apiServer}/api/trucks/${id}`),
	getAll: () => WebClient.Get<Truck[]>(`${apiServer}/api/trucks`),
	create: (request: CreateTruckRequest) => WebClient.Post.Validated<Truck>(`${apiServer}/api/trucks`, request),
	update: (request: UpdateTruckRequest) => WebClient.Put.Validated<Truck>(`${apiServer}/api/trucks`, serializeUpdateRequest(request)),
	assignInspectionList: (request: {truckId: string, inspectionListId: string | null}) =>
		WebClient.Put.Validated<Truck>(`${apiServer}/api/trucks/${request.truckId}/inspection-list`, request),
};
