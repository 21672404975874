import React, { useEffect } from "react";
import OverlayLoadingScreen from "../../components/OverlayLoadingScreen";
import { PageTitle } from "../../components/PageTitle";
import { ServerErrorView } from "../../components/ServerErrorView";
import { Customer } from "../../entities/customer/Customer";
import { CustomerProfile, CustomerService } from "../../services/customer/CustomerService";
import { ServerError } from "../../services/server/WebClient";
import { CustomerDetailView } from "./CustomerDetailView";

interface CustomerDetailPageContext {
	profile: CustomerProfile;
	customer: Customer;
	canDelete: boolean;
	updateCustomer: (customer: Customer) => void;
	updateProfile: (profile: (p: CustomerProfile) => CustomerProfile) => void;
	hideDeleteButton: () => void;
}

const CustomerDetailPageReactContext = React.createContext<CustomerDetailPageContext | undefined>(undefined);

export function useCustomerDetailPage() {
	const context = React.useContext(CustomerDetailPageReactContext);
	if (!context) {
		throw new Error("useCustomerDetailPage() must be used within a CustomerDetailPage");
	}
	return context;
}

export function CustomerDetailPage(props: { customerId: string }) {
	const { customerId } = props;

	const [serverError, setServerError] = React.useState<ServerError>();
	const [profile, setProfile] = React.useState<CustomerProfile>();
	const [showDelete, setShowDelete] = React.useState(true);
	const [twoSecondDelay, setTwoSecondDelay] = React.useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setTwoSecondDelay(true), 2000);
		return () => clearTimeout(timer);
	}, []);

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const updateCustomer = (customer: Customer) => setProfile((p) => ({ ...p!, customer }));

	useEffect(() => {
		const fetchCustomer = async () => {
			const result = await CustomerService.getProfile(customerId);
			if (result.success) {
				setProfile(result.data);
			} else {
				setServerError(result);
			}
		};
		fetchCustomer();
	}, [customerId]);

	const hideDeleteButton = React.useCallback(() => setShowDelete(false), []);

	if (serverError) {
		return <ServerErrorView serverError={serverError} />;
	}

	if (!profile) {
		return <OverlayLoadingScreen />;
	}

	return (
		<CustomerDetailPageReactContext.Provider
			value={{
				profile,
				updateProfile: d=> setProfile(p => p ? d(p) : p),
				customer: profile.customer,
				updateCustomer,
				canDelete: profile.customer.balance === 0 && twoSecondDelay && showDelete,
				hideDeleteButton
			}}
		>
			<PageTitle title={profile.customer.name} />
			<CustomerDetailView />
		</CustomerDetailPageReactContext.Provider>
	);
}
