import { Card, Box, Typography, Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Customer } from "../../../../entities/customer/Customer";

import { useCustomerDetailPage } from "../../CustomerDetailPage";
import { PaymentMethodSection } from "./PaymentMethodSection";
import { PaymentTermsSection } from "./PaymentTermsSection";
import { useAlert } from "../../../../hooks/useAlert";
import { TransactionService } from "../../../../services/accounting/TransactionService";
import { PaymentTransaction } from "../../../../entities/accounting/PaymentTransaction";
import { Price } from "../../../../components/Price";
import { ApplyPaymentDialog } from "../../dialog/ApplyPaymentDialog";
import { GridGrow } from "../../../../components/GridGrow";
import { Invoice, SearchableInvoice } from "../../../../entities/billing/Invoice";
import { InvoiceService } from "../../../../services/billing/InvoiceService";
import { InvoicesSection } from "./InvoicesSection";
import { PaymentsSection } from "./PaymentsSection";
import { CustomerService } from "../../../../services/customer/CustomerService";
import { TaxExemptionSection } from "./TaxExemptionSection";
import { RestrictToRole } from "../../../../components/RestrictToRole";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import { KeyboardDatePicker } from "@material-ui/pickers";
import format from "date-fns/format";
import { ContractsSection } from "./ContractsSection";

interface CustomerPaymentCardContext {
	customer: Customer;
	paymentTransactions: PaymentTransaction[] | undefined;
	invoices: SearchableInvoice[] | undefined;
	disabled: boolean;
	setDisabled: (disabled: boolean) => void;
}

const CustomerPaymentCardReactContext = React.createContext<CustomerPaymentCardContext>({} as CustomerPaymentCardContext);
export const useCustomerPaymentCardContext = () => React.useContext(CustomerPaymentCardReactContext);

export function CustomerPaymentCard() {
	const alert = useAlert();
	const context = useCustomerDetailPage();
	const { customer, hideDeleteButton } = context;
	const customerId = customer.id;

	const [disabled, setDisabled] = useState(false);
	const [balance, setBalance] = useState(customer.balance);
	const [openApplyPaymentDialog, setOpenApplyPaymentDialog] = useState(false);
	const [openDownloadStatementDialog, setOpenDownloadStatementDialog] = useState(false);

	const [paymentTransactions, setPaymentTransactions] = useState<PaymentTransaction[]>();
	const [invoices, setInvoices] = useState<SearchableInvoice[]>();

	useEffect(() => {
		const fetchPayments = async () => {
			const result = await TransactionService.searchPaymentTransactions({ customerId, type: "all" });
			if (result.success) {
				setPaymentTransactions(result.data);
				if (result.data.length > 0) hideDeleteButton();
			} else if (result.validation) {
				alert.validation(result);
			} else {
				alert.serverError(result);
			}
		};
		fetchPayments();
	}, [customerId, alert, hideDeleteButton]);

	useEffect(() => {
		async function fetchInvoices() {
			const result = await InvoiceService.getCustomerInvoices(customerId);
			if (result.success) {
				setInvoices(result.data);
				if (result.data.length > 0) hideDeleteButton();
			} else {
				alert.serverError(result);
			}
		}
		fetchInvoices();
	}, [alert, customerId, hideDeleteButton]);

	const onPaymentApplied = (updatedInvoices: Invoice[], paymentTransaction: PaymentTransaction) => {
		setOpenApplyPaymentDialog(false);
		setBalance((balance) => balance - paymentTransaction.amount);
		setPaymentTransactions((pt) => [...(pt ?? []), paymentTransaction]);
		setInvoices((invoices) =>
			invoices?.map((i) => {
				const updatedInvoice = updatedInvoices.find((ui) => ui.id === i.id);
				if (updatedInvoice) {
					return { ...i, ...updatedInvoice };
				}
				return i;
			})
		);
	};

	return (
		<Card style={{ paddingBottom: 6 }}>
			<CustomerPaymentCardReactContext.Provider value={{ customer, paymentTransactions, invoices, disabled, setDisabled }}>
				<ApplyPaymentDialog
					customerId={customer.id}
					open={openApplyPaymentDialog}
					onClose={() => setOpenApplyPaymentDialog(false)}
					onPaymentApplied={onPaymentApplied}
				/>
				<DownloadStatementDialog open={openDownloadStatementDialog} onClose={() => setOpenDownloadStatementDialog(false)} />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Box m={1} ml={2} mb={0}>
							<Grid container spacing={1} alignItems="center">
								<Grid item>
									<Typography variant="h6">
										Account Balance: <Price value={balance} />
									</Typography>
								</Grid>
								<GridGrow />
								<Grid item>
									<Button variant="outlined" onClick={() => setOpenDownloadStatementDialog(true)} style={{ marginRight: 5 }}>
										Download Statement
									</Button>
									<RestrictToRole admin>
										<Button variant="outlined" color="primary" onClick={() => setOpenApplyPaymentDialog(true)}>
											Apply Payment
										</Button>
									</RestrictToRole>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid item xs={12} style={{ marginBottom: 10 }}>
						<PaymentsSection />
					</Grid>
					<Grid item xs={12} style={{ marginBottom: 10 }}>
						<InvoicesSection />
					</Grid>
					<Grid item xs={12} style={{ marginBottom: 10 }}>
						<PaymentMethodSection />
					</Grid>
					<Grid item xs={6}>
						<PaymentTermsSection />
					</Grid>
					<Grid item xs={6}>
						<TaxExemptionSection />
					</Grid>
					<Grid item xs={12}>
						<ContractsSection />
					</Grid>
				</Grid>
			</CustomerPaymentCardReactContext.Provider>
		</Card>
	);
}

function DownloadStatementDialog(props: { open: boolean; onClose: () => void }) {
	const alert = useAlert();
	const context = useCustomerPaymentCardContext();
	const { customer, disabled, setDisabled } = context;

	const today = new Date();
	const startDate = startOfMonth(today);
	const endDate = endOfMonth(today);
	const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(startDate);
	const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(endDate);
	const [lastSentDate, setLastSentDate] = useState<Date | null>(customer.lastStatementDate);
	const [markAsSent, setMarkAsSent] = useState(true);

	const downloadStatement = async () => {
		if (!selectedStartDate || !selectedEndDate) {
			alert.error("Please select a start and end date.");
			return;
		}
		setDisabled(true);
		const safeName = customer.name.replace(/[^\w]/gi, "");
		const fileName = `Statement for ${safeName} ${format(selectedStartDate, "M-d-yy")} - ${format(selectedEndDate, "M-d-yy")}.pdf`;
		const result = await CustomerService.downloadStatement({ customerId: customer.id, startDate: selectedStartDate, endDate: selectedEndDate, markAsSent }, fileName);
		setDisabled(false);
		if (result.success) {
			setLastSentDate(new Date());
			props.onClose();
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={props.open} onClose={props.onClose} maxWidth="xs" fullWidth>
			<DialogTitle>Download Statement</DialogTitle>
			<DialogContent>
				<DialogContentText>Select period to download statement for.</DialogContentText>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<KeyboardDatePicker
							inputVariant="outlined"
							label="Start Date"
							value={selectedStartDate}
							onChange={(date) => setSelectedStartDate(date)}
							format="MM/dd/yyyy"
						/>
					</Grid>
					<Grid item xs={6}>
						<KeyboardDatePicker
							inputVariant="outlined"
							label="End Date"
							value={selectedEndDate}
							onChange={(date) => setSelectedEndDate(date)}
							format="MM/dd/yyyy"
						/>
					</Grid>
				</Grid>

				
				<FormControlLabel
					control={<Checkbox checked={markAsSent} onChange={(event) => setMarkAsSent(event.target.checked)} color="primary" />}
					label="Mark as sent?"
				/>
				<DialogContentText>Last Statement Sent: {lastSentDate ? lastSentDate.toLocaleDateString() : "Never"}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="secondary" variant="outlined" disabled={disabled}>
					Cancel
				</Button>
				<Button onClick={downloadStatement} color="primary" variant="contained" disabled={disabled}>
					Download
				</Button>
			</DialogActions>
		</Dialog>
	);
}
