import { deserializeTaxExemption, deserializeTaxExemptions, TaxExemptionDocument } from "../../entities/accounting/TaxExemption";
import { NodaTime } from "../../utility/NodaTime";
import { toOptional } from "../../utility/OptionalValue";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";


interface CreateCustomerTaxExemptionRequest {
    customerId: string;
    expirationDate: Date | null;
    notes: string;
}

interface CreateTankTaxExemptionRequest {
    customerId: string;
    tankId: string;
    expirationDate: Date | null;
    notes: string;
}

interface UpdateTaxExemptionRequest {
    taxExemptionId: string;
    expirationDate?: Date | null;
    notes?: string;
}

interface UploadExemptionDocumentRequest {
    taxExemptionId: string;
    file: File;
    expirationDate: Date | null;
}

const toCreateRequest = (request: CreateCustomerTaxExemptionRequest | CreateTankTaxExemptionRequest) => ({
    ...request, 
    expirationDate: request.expirationDate ? NodaTime.serializeToLocalDate(request.expirationDate) : null
});

const toUpdateRequest = (request: UpdateTaxExemptionRequest) => ({
    ...request, 
    expirationDate: toOptional(request.expirationDate ? NodaTime.serializeToLocalDate(request.expirationDate) : null)
});

const toUploadRequest = (request: UploadExemptionDocumentRequest) => {
    const { file, expirationDate } = request;
    const formData = new FormData();
    formData.append("file", file);
    if(expirationDate){
        formData.append("expirationDate", NodaTime.serializeToLocalDate(expirationDate));
    }
    return formData;
}

export const TaxExemptionService = {
    getCustomerTaxExemptions: async (customerId: string) => WebClient.Get(`${apiServer}/api/tax-exemptions/customer/${customerId}`, deserializeTaxExemptions),
    createCustomerTaxExemption: async (request: CreateCustomerTaxExemptionRequest) => WebClient.Post.Validated(`${apiServer}/api/tax-exemptions/customer`, toCreateRequest(request), deserializeTaxExemption),
    createTankTaxExemption: async (request: CreateTankTaxExemptionRequest) => WebClient.Post.Validated(`${apiServer}/api/tax-exemptions/tank`, toCreateRequest(request), deserializeTaxExemption),
    update: async (request: UpdateTaxExemptionRequest) => WebClient.Put.Validated(`${apiServer}/api/tax-exemptions`, toUpdateRequest(request), deserializeTaxExemption),
    delete: async (taxExemptionId: string) => WebClient.Delete(`${apiServer}/api/tax-exemptions/${taxExemptionId}`),
    uploadDocument: async (request: UploadExemptionDocumentRequest) => WebClient.Post.Validated(`${apiServer}/api/tax-exemptions/${request.taxExemptionId}/documents`, toUploadRequest(request), deserializeTaxExemption),
    downloadDocument: async (document: TaxExemptionDocument) => WebClient.Download.Get(`${apiServer}/api/tax-exemptions/${document.taxExemptionId}/documents/${document.id}`, document.fileName),
}