import { Collapse, Divider, Grid, Hidden, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import LocalShippingRoundedIcon from "@material-ui/icons/LocalShippingRounded";
import PeopleIcon from "@material-ui/icons/People";
import ListAltIcon from "@material-ui/icons/ListAlt";
import RouterIcon from "@material-ui/icons/Router";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { routes } from "../routes";
import { ReactComponent as WhiteLogo } from "./logos/DPLogoWhite.svg";
import { ReactComponent as GreyLogo } from "./logos/DPLogoGrey.svg";
import { GridGrow } from "../components/GridGrow";
import { appVersion } from "../appVersion";
import { RestrictToRole } from "../components/RestrictToRole";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useLocalStorage } from "../utility/useLocalStorage";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { useAppUser } from "../providers/AppUserProvider";
import { IntegrationType } from "../entities/auth/IntegrationConfiguration";
import PageviewIcon from "@material-ui/icons/Pageview";
import PlaceIcon from "@material-ui/icons/Place";

export default function MainDrawerItems(props: { onClose: () => void }) {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const user = useAppUser();
	const { driverOnly, driverId } = user;

	const [expandBilling, setExpandBilling] = useLocalStorage("expandBilling", false);
	const [expandTickets, setExpandTickets] = useLocalStorage("expandTickets", false);

	const onRouteSelected = (path: string) => {
		history.push(path);
		props.onClose();
	};

	return (
		<Grid container direction="column" wrap="nowrap" style={{ height: "100%" }}>
			<Grid item>
				<Hidden mdUp>
					<div className={classes.drawerHeader}>
						<WhiteLogo style={{ height: 55, width: "auto", padding: 10, marginLeft: "auto", marginRight: "auto", display: "block" }} id="logo-header" />
					</div>
					<Divider />
				</Hidden>
				<List>
					{driverId && driverOnly ? (
						<ListItem button onClick={() => onRouteSelected(routes.app.resolve.driverRoutePage(driverId))}>
							<ListItemIcon>
								<LocalShippingRoundedIcon />
							</ListItemIcon>
							<ListItemText primary="My Route" />
						</ListItem>
					) : (
						<RestrictToRole office>
							<ListItem button onClick={() => onRouteSelected(routes.app.resolve.routeListPage())}>
								<ListItemIcon>
									<LocalShippingRoundedIcon />
								</ListItemIcon>
								<ListItemText primary="Routes" />
							</ListItem>
						</RestrictToRole>
					)}
					<RestrictToRole office>
						<ListItem button onClick={() => onRouteSelected(routes.app.resolve.customerListPage())}>
							<ListItemIcon>
								<PeopleIcon />
							</ListItemIcon>
							<ListItemText primary="Customers" />
						</ListItem>
					</RestrictToRole>
					<RestrictToRole office>
						<ListItem button onClick={() => onRouteSelected(routes.app.resolve.propaneTankSearchPage())}>
							<ListItemIcon>
								<PageviewIcon />
							</ListItemIcon>
							<ListItemText primary="Tank Search" />
						</ListItem>
					</RestrictToRole>
					<RestrictToRole superAdmin>
						<ListItem button onClick={() => onRouteSelected(routes.routeBuilder.resolve.builderPage())}>
							<ListItemIcon>
								<PlaceIcon />
							</ListItemIcon>
							<ListItemText primary="Route Builder" />
						</ListItem>
					</RestrictToRole>
					<RestrictToRole office>
						<ListItem button onClick={() => setExpandTickets(!expandTickets)}>
							<ListItemIcon>
								<ListAltIcon />
							</ListItemIcon>
							<ListItemText primary="Tickets" />
							{expandTickets ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={expandTickets} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								<ListItem button className={classes.nestedItem} onClick={() => onRouteSelected(routes.app.resolve.openTicketsPage())}>
									<ListItemText>Open Tickets</ListItemText>
								</ListItem>
							</List>
							<List component="div" disablePadding>
								<ListItem button className={classes.nestedItem} onClick={() => onRouteSelected(routes.app.resolve.reviewTicketsPage())}>
									<ListItemText>Review</ListItemText>
								</ListItem>
							</List>
							<List component="div" disablePadding>
								<ListItem button className={classes.nestedItem} onClick={() => onRouteSelected(routes.app.resolve.ticketSearchPage())}>
									<ListItemText>Search</ListItemText>
								</ListItem>
							</List>
						</Collapse>
					</RestrictToRole>
					{user.enabledIntegrations.some((i) => i === IntegrationType.Otodata) && (
						<RestrictToRole office>
							<ListItem button onClick={() => onRouteSelected(routes.app.resolve.tankMonitorDispatchProListPage())}>
								<ListItemIcon>
									<RouterIcon />
								</ListItemIcon>
								<ListItemText primary="Tank Monitors" />
							</ListItem>
						</RestrictToRole>
					)}
					<RestrictToRole admin>
						<ListItem button onClick={() => onRouteSelected(routes.app.resolve.accountingDashboardPage())}>
							<ListItemIcon>
								<AttachMoneyIcon />
							</ListItemIcon>
							<ListItemText>Accounting</ListItemText>
						</ListItem>
					</RestrictToRole>
					<RestrictToRole admin>
						<ListItem button onClick={() => setExpandBilling(!expandBilling)}>
							<ListItemIcon>
								<AssignmentIcon />
							</ListItemIcon>
							<ListItemText primary="Billing" />
							{expandBilling ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={expandBilling} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								<ListItem button className={classes.nestedItem} onClick={() => onRouteSelected(routes.app.resolve.invoiceSearchPage())}>
									<ListItemText>Invoices</ListItemText>
								</ListItem>
							</List>
							<List component="div" disablePadding>
								<ListItem button className={classes.nestedItem} onClick={() => onRouteSelected(routes.app.resolve.paymentsPage())}>
									<ListItemText>Payments</ListItemText>
								</ListItem>
							</List>
						</Collapse>
					</RestrictToRole>
					<RestrictToRole office>
						<ListItem button onClick={() => onRouteSelected(routes.app.resolve.reportListPage())}>
							<ListItemIcon>
								<AssessmentIcon />
							</ListItemIcon>
							<ListItemText primary="Reports" />
						</ListItem>
					</RestrictToRole>
				</List>
			</Grid>
			<GridGrow />
			<Grid item>
				<Hidden smDown>
					{theme.palette.type === "dark" ? (
						<WhiteLogo style={{ height: 30, width: "auto", marginBottom: 5, marginLeft: "auto", marginRight: "auto", display: "block" }} id="logo-header" />
					) : (
						<GreyLogo style={{ height: 30, width: "auto", marginBottom: 5, marginLeft: "auto", marginRight: "auto", display: "block" }} id="logo-header" />
					)}
				</Hidden>
				<Grid container alignItems="center" justify="center">
					<Grid item>
						<Typography align="center" variant="overline" style={{ textTransform: "unset", fontWeight: 300 }}>
							DispatchPro {appVersion}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	drawerHeader: {
		...theme.mixins.toolbar,
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.getContrastText(theme.palette.secondary.main),
	},
	nestedItem: {
		paddingLeft: theme.spacing(4),
	},
}));
