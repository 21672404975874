import { Divider, Grid, Hidden, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import StoreIcon from "@material-ui/icons/Store";
import { routes } from "../routes";
import { ReactComponent as WhiteLogo } from "./logos/DPLogoWhite.svg";
import { ReactComponent as GreyLogo } from "./logos/DPLogoGrey.svg";
import { GridGrow } from "../components/GridGrow";
import { appVersion } from "../appVersion";
import { RestrictToRole } from "../components/RestrictToRole";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BusinessIcon from '@material-ui/icons/Business';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import GroupIcon from '@material-ui/icons/Group';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EventNoteIcon from '@material-ui/icons/EventNote';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import LocalShippingRoundedIcon from "@material-ui/icons/LocalShippingRounded";
import LanguageIcon from '@material-ui/icons/Language';

export default function BusinessDrawerItems(props: { onClose: () => void }) {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();


	const onRouteSelected = (path: string) => {
		history.push(path);
		props.onClose();
	};

	return (
		<Grid container direction="column" style={{ height: "100%" }}>
			<Grid item>
				<Hidden mdUp>
					<div className={classes.drawerHeader}>
						<WhiteLogo style={{ height: 55, width: "auto", padding: 10, marginLeft: "auto", marginRight: "auto", display: "block" }} id="logo-header" />
					</div>
					<Divider />
				</Hidden>
				<List>
					<ListItem button onClick={() => onRouteSelected("/app")}>
						<ListItemIcon>
							<ArrowBackIcon />
						</ListItemIcon>
						<ListItemText>Back</ListItemText>
					</ListItem>
					<RestrictToRole admin>
						<ListItem button onClick={() => onRouteSelected(routes.business.resolve.companyProfilePage())}>
							<ListItemIcon>
								<BusinessIcon />
							</ListItemIcon>
							<ListItemText>Company Profile</ListItemText>
						</ListItem>
					</RestrictToRole>
					<RestrictToRole admin>
						<ListItem button onClick={() => onRouteSelected(routes.business.resolve.documentsPage())}>
							<ListItemIcon>
								<LibraryBooksIcon />
							</ListItemIcon>
							<ListItemText>Documents</ListItemText>
						</ListItem>
					</RestrictToRole>
					<RestrictToRole admin>
						<ListItem button onClick={() => onRouteSelected(routes.business.resolve.onlineProfilePage())}>
							<ListItemIcon>
								<LanguageIcon />
							</ListItemIcon>
							<ListItemText>Online Profile</ListItemText>
						</ListItem>
					</RestrictToRole>
					<RestrictToRole admin>
						<ListItem button onClick={() => onRouteSelected(routes.business.resolve.userPage())}>
							<ListItemIcon>
								<GroupIcon />
							</ListItemIcon>
							<ListItemText>Users</ListItemText>
						</ListItem>
					</RestrictToRole>
					<RestrictToRole admin>
						<ListItem button onClick={() => onRouteSelected(routes.business.resolve.productCatalogPage())}>
							<ListItemIcon>
								<StoreIcon />
							</ListItemIcon>
							<ListItemText>Product Catalog</ListItemText>
						</ListItem>
					</RestrictToRole>
					<RestrictToRole admin>
						<ListItem button onClick={() => onRouteSelected(routes.business.resolve.generalLedgerAccountsPage())}>
							<ListItemIcon>
								<MenuBookIcon />
							</ListItemIcon>
							<ListItemText>Ledger Accounts</ListItemText>
						</ListItem>
					</RestrictToRole>
					<RestrictToRole admin>
						<ListItem button onClick={() => onRouteSelected(routes.business.resolve.taxRulesPage())}>
							<ListItemIcon>
								<AccountBalanceIcon />
							</ListItemIcon>
							<ListItemText>Taxes</ListItemText>
						</ListItem>
					</RestrictToRole>
					<RestrictToRole admin>
						<ListItem button onClick={() => onRouteSelected(routes.business.resolve.paymentTermsPage())}>
							<ListItemIcon>
								<EventNoteIcon />
							</ListItemIcon>
							<ListItemText>Payment Terms</ListItemText>
						</ListItem>
					</RestrictToRole>
					<RestrictToRole admin>
						<ListItem button onClick={() => onRouteSelected(routes.business.resolve.trucksPage())}>
							<ListItemIcon>
								<LocalShippingRoundedIcon />
							</ListItemIcon>
							<ListItemText>Trucks</ListItemText>
						</ListItem>
					</RestrictToRole>
					<RestrictToRole admin>
						<ListItem button onClick={() => onRouteSelected(routes.business.resolve.integrationPage())}>
							<ListItemIcon>
								<DeviceHubIcon />
							</ListItemIcon>
							<ListItemText>Integrations</ListItemText>
						</ListItem>
					</RestrictToRole>
				</List>
			</Grid>
			<GridGrow />
			<Grid item>
				<Hidden smDown>
					{theme.palette.type === "dark" ? (
						<WhiteLogo style={{ height: 30, width: "auto", marginBottom: 5, marginLeft: "auto", marginRight: "auto", display: "block" }} id="logo-header" />
					) : (
						<GreyLogo style={{ height: 30, width: "auto", marginBottom: 5, marginLeft: "auto", marginRight: "auto", display: "block" }} id="logo-header" />
					)}
				</Hidden>
				<Grid container alignItems="center" justify="center">
					<Grid item>
						<Typography align="center" variant="overline" style={{ textTransform: "unset", fontWeight: 300 }}>
							DispatchPro {appVersion}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	drawerHeader: {
		...theme.mixins.toolbar,
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.getContrastText(theme.palette.secondary.main),
	},
	nestedItem: {
		paddingLeft: theme.spacing(4),
	},
}));
