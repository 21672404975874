import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

export interface TenantAdminModel {
    id: string;
    name: string;
    timezone: string;
}

export const AdminService = {
    getTenants: () => WebClient.Get<TenantAdminModel[]>(`${apiServer}/api/admin/tenants`),
    changeTenant: (tenantId: string) => WebClient.Post.Unvalidated<{jwt: string}>(`${apiServer}/api/admin/change-tenant`, { tenantId }),
};