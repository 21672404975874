import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { BackButton } from "../../../components/BackButton";
import { ButtonLink } from "../../../components/ButtonLink";
import { GridGrow } from "../../../components/GridGrow";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { PageTitle } from "../../../components/PageTitle";
import { ServerErrorView } from "../../../components/ServerErrorView";
import { EditableField } from "../../../components/SubmitTextField";
import { VehicleInspectionList } from "../../../entities/routing/VehicleInspectionList";
import { useAlert } from "../../../hooks/useAlert";
import { routes } from "../../../routes";
import { VehicleInspectionListService } from "../../../services/routing/VehicleInspectionListService";
import { ServerError } from "../../../services/server/WebClient";

export function InspectionListDetailPage() {
	const params = useParams<{ inspectionListId: string }>();
	const inspectionListId = params.inspectionListId;

	const [inspectionList, setInspectionList] = React.useState<VehicleInspectionList>();
	const [serverError, setServerError] = React.useState<ServerError>();

	React.useEffect(() => {
		async function fetchInspectionList() {
			const result = await VehicleInspectionListService.get(inspectionListId);
			if (result.success) {
				setInspectionList(result.data);
			} else {
				setServerError(result);
			}
		}
		fetchInspectionList();
	}, [inspectionListId]);

	if (serverError) {
		return <ServerErrorView serverError={serverError} />;
	}

	if (!inspectionList) return <OverlayLoadingScreen />;

	return <InspectionListView inspectionList={inspectionList} onUpdate={setInspectionList} />;
}

interface Props {
	inspectionList: VehicleInspectionList;
	onUpdate: (inspectionList: VehicleInspectionList) => void;
}

function InspectionListView(props: Props) {
	const { inspectionList } = props;
	const alert = useAlert();
    const history = useHistory();

	const [disabled, setDisabled] = React.useState(false);

	const removeItem = async (itemId: number) => {
		setDisabled(true);
		const result = await VehicleInspectionListService.removeItem({ listId: inspectionList.id, itemId });
		setDisabled(false);
		if (result.success) {
			alert.success("Item removed from list");
			props.onUpdate(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

    const deleteList = async () => {
        setDisabled(true);
        const result = await VehicleInspectionListService.delete(inspectionList.id);
        setDisabled(false);
        if (result.success) {
            alert.success("Inspection list deleted");
            history.replace(routes.business.resolve.trucksPage());
        } else if (result.validation) {
            alert.validation(result);
        } else {
            alert.serverError(result);
        }
    }

	return (
		<Grid container>
			<PageTitle title={`Inspection List - ${inspectionList.name}`} />
			<Grid item xs={12}>
				<Grid container alignItems="center">
					<Grid item>
						<BackButton />
					</Grid>
					<Grid item>
						<Typography variant="h5">Inspection List - {inspectionList.name}</Typography>
					</Grid>
                    <GridGrow/>
                    <Grid item>
                        <Button variant="outlined" color="secondary" onClick={deleteList} disabled={disabled}>
                            Delete
                        </Button>
                        </Grid>
				</Grid>
			</Grid>
			<Grid item lg={6} md={8} sm={10} xs={12}>
				<Card>
					<CardContent>
						{inspectionList.items.length > 0 && (
							<>
								<Typography variant="h6" color="textSecondary">
									Inspection points
								</Typography>

								{inspectionList.items.map((item, i) => (
									<Typography key={item.id} style={{ fontSize: 17, margin: 10 }}>
										{i + 1}. {item.name}{" "}
										<ButtonLink underline="none" color="error" onClick={() => removeItem(item.id)} disabled={disabled}>
											(Remove)
										</ButtonLink>
									</Typography>
								))}
							</>
						)}
                        {inspectionList.items.length === 0 && (
                            <Typography variant="h6" style={{ marginBottom: 10 }}>
                                No inspection points
                            </Typography>
                        )}
						<EditableField<VehicleInspectionList>
							label="Inspection point name"
							value=""
							type="text"
							update={{
								request: (name) => VehicleInspectionListService.addItem({ listId: inspectionList.id, name: name }),
								onSuccess: props.onUpdate,
								successMessage: "Item added",
								required: true,
							}}
							view={(onEdit) => (
								<Button onClick={onEdit} variant="contained" color="primary">
									Add Item
								</Button>
							)}
						/>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}


