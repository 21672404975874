import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import OverlayLoadingScreen from "../../components/OverlayLoadingScreen";
import { ServerErrorView } from "../../components/ServerErrorView";
import { DriverTicket } from "../../entities/routing/DriverTicket";
import { ServerError } from "../../services/server/WebClient";
import { DriverTicketService } from "../../services/routing/DriverTicketService";
import { DriverTicketDetailView } from "./ticket-detail/DriverTicketDetailView";

export function DriverTicketDetailPage() {
	const params = useParams<{ ticketId: string }>();
	const { ticketId } = params;
	const history = useHistory();

	const [ticket, setTicket] = useState<DriverTicket>();
	const [serverError, setServerError] = useState<ServerError | undefined>();

	useEffect(() => {
		async function fetch() {
			const ticketResult = await DriverTicketService.get(ticketId);
			if (ticketResult.success) {
				setTicket(ticketResult.data);
			} else {
				setServerError(ticketResult);
			}
		}
		fetch();
	}, [ticketId]);

	if (serverError) {
		return <ServerErrorView serverError={serverError} />;
	}

	if (!ticket) {
		return <OverlayLoadingScreen />;
	}

	return <DriverTicketDetailView ticket={ticket} updateTicket={setTicket} onBack={() => history.goBack()} />;
}
