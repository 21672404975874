import { deserializePaymentTransaction } from "../../entities/accounting/PaymentTransaction";
import { deserializeInvoice, deserializeInvoices, deserializeSearchableInvoices, SearchableInvoice } from "../../entities/billing/Invoice";
import { NodaTime } from "../../utility/NodaTime";
import { OptionalValue, toOptional } from "../../utility/OptionalValue";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";


export interface ApplyPaymentRequestBase {
	customerId: string;
	amount: number;
	appliesTo: { invoiceId: string; amountApplied: number }[];
}

interface ApplyCashPaymentRequest extends ApplyPaymentRequestBase {
	paymentType: "cash";
	receivedOnDate: string | null;
}

interface ApplyCheckPaymentRequest extends ApplyPaymentRequestBase {
	paymentType: "check";
	checkNumber: string;
	receivedOnDate: string | null;
}

interface ApplyCardPaymentRequest extends ApplyPaymentRequestBase {
	paymentType: "card";
	paymentMethodId: string;
	surchargeAmount: number;
	sessionId: string | null;
}

export type ApplyNewPaymentRequest = ApplyCashPaymentRequest | ApplyCheckPaymentRequest | ApplyCardPaymentRequest;

interface RemoveInvoicePaymentRequest {
	invoiceId: string;
	invoicePaymentId: string;
}

interface AddInvoiceFeeRequest {
	invoiceId: string;
	feeProductListingId: string;
	amount: number;
	description: string;
}

interface AddInvoiceSaleRequest {
	invoiceId: string;
	productListingId: string;
	quantity: number;
	unitPrice: number;
	description: string;
}

interface AddInvoiceDiscountRequest {
	invoiceId: string;
	invoiceItemId: string;
	amount: number;
	description: string;
}

interface RemoveInvoiceSaleRequest {
	invoiceId: string;
	invoiceItemId: string;
	reason: string | null;
}

interface AddTaxToInvoiceRequest {
	invoiceId:string, 
	taxAccountId: number, 
	taxAmount: number, 
	description: string | null;
}

interface SearchInvoicesRequest {
	status: "open" | "closed" | "all";
	customerCode?: string;
	customerId?: string;
	invoiceCode?: string;
	amount?: number;
	daysOverdue?: number;
	ticketNumber?: string;
	page?: number;
	pageSize?: number;
	sortBy?: string | null;
	sortOrder?: "asc" | "desc";
}

interface SearchInvoicesResponse {
	results: SearchableInvoice[];
	totalCount : number;
	page: number;
	pageSize: number | null;
	totalPages: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function deserializeSearchInvoicesResponse(response: any): SearchInvoicesResponse {
	return {
		...response,
		results: deserializeSearchableInvoices(response.results)
	};
}

interface UpdateInvoiceRequest {
	invoiceId: string;
	notes?: string | null;
	paymentTermsId?: string;
	dueBy?: Date;
	code?: string;
}

type SerializedUpdateInvoiceRequest = Omit<UpdateInvoiceRequest, "notes" | "dueBy"> & { 
	notes?: OptionalValue<string | null> 
	dueBy?: string
};

function deserializeUpdateInvoiceRequest(request: UpdateInvoiceRequest): SerializedUpdateInvoiceRequest {
	return {
		...request,
		notes: toOptional(request.notes),
		dueBy: request.dueBy ? NodaTime.serializeToLocalDate(request.dueBy) : undefined
	};
}

interface UpdateInvoiceItemRequest {
	invoiceId: string;
	invoiceItemId: string;
	description?: string;
}

export const InvoiceService = {
	getOpen: () => WebClient.Get(`${apiServer}/api/invoices/open`, deserializeSearchableInvoices),
	get: (id: string) => WebClient.Get(`${apiServer}/api/invoices/${id}`, deserializeInvoice),
	search: (request: SearchInvoicesRequest) => WebClient.Post.Validated(`${apiServer}/api/invoices/search`, request, deserializeSearchInvoicesResponse),
	download: (invoiceId: string, fileName: string) => WebClient.Download.Get(`${apiServer}/api/invoices/${invoiceId}/download`, fileName, "application/pdf"),
	getCustomerInvoices: (customerId: string) => WebClient.Get(`${apiServer}/api/invoices/customer/${customerId}`, deserializeSearchableInvoices),
	update: (request: UpdateInvoiceRequest) => WebClient.Put.Validated(`${apiServer}/api/invoices/${request.invoiceId}`, deserializeUpdateInvoiceRequest(request), deserializeInvoice),
	updateItem: (request: UpdateInvoiceItemRequest) => WebClient.Put.Validated(`${apiServer}/api/invoices/${request.invoiceId}/item/${request.invoiceItemId}`, request, deserializeInvoice),
	applyNewPayment: (request: ApplyNewPaymentRequest) =>
		WebClient.Post.Validated(`${apiServer}/api/invoices/payment`, request, (r) => ({
			invoices: deserializeInvoices(r.invoices),
			paymentTransaction: deserializePaymentTransaction(r.paymentTransaction),
		})),
	removePayment: (request: RemoveInvoicePaymentRequest) =>
		WebClient.Post.Validated(`${apiServer}/api/invoices/payment/remove`, request, (response) => ({
			invoice: deserializeInvoice(response.invoice),
			paymentTransaction: deserializePaymentTransaction(response.paymentTransaction),
		})),
	addFee: (request: AddInvoiceFeeRequest) => WebClient.Post.Validated(`${apiServer}/api/invoices/${request.invoiceId}/fee`, request, deserializeInvoice),
	addSale: (request: AddInvoiceSaleRequest) => WebClient.Post.Validated(`${apiServer}/api/invoices/${request.invoiceId}/sale`, request, deserializeInvoice),
	addDiscount: (request: AddInvoiceDiscountRequest) =>
		WebClient.Post.Validated(`${apiServer}/api/invoices/${request.invoiceId}/${request.invoiceItemId}/discount`, request, deserializeInvoice),
	removeSale: (request: RemoveInvoiceSaleRequest) =>
		WebClient.Post.Validated(`${apiServer}/api/invoices/${request.invoiceId}/${request.invoiceItemId}/remove`, request, deserializeInvoice),
	voidInvoice: (request: {invoiceId: string, reason: string | null}) => WebClient.Post.Validated(`${apiServer}/api/invoices/${request.invoiceId}/void`, request, deserializeInvoice),
	removeTax: (request: {invoiceId: string, invoiceItemId: string, reason: string | null}) => 
		WebClient.Post.Validated(`${apiServer}/api/invoices/${request.invoiceId}/${request.invoiceItemId}/remove-tax`, request, deserializeInvoice),
	addTaxToInvoice: (request: AddTaxToInvoiceRequest) => WebClient.Post.Validated(`${apiServer}/api/invoices/${request.invoiceId}/tax`, request, deserializeInvoice),
	transfer : (request: {invoiceId: string, customerId: string}) => WebClient.Put.Validated(`${apiServer}/api/invoices/transfer`, request, deserializeInvoice),
	emailInvoice: (invoiceId: string) => WebClient.Post.Validated(`${apiServer}/api/invoices/email`, {invoiceId}),
};
