import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	IconButton,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FieldValidationError, validationError } from "../../../services/server/ServerValidationError";
import { Enum } from "../../../utility/Enum";
import { RedButton } from "../../../components/RedButton";
import { useAlert } from "../../../hooks/useAlert";
import { User } from "../../../entities/auth/User";
import { BusinessService } from "../../../services/business/BusinessService";
import { UserClaim } from "../../../entities/auth/UserClaim";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { UserStatus } from "../../../entities/auth/UserStatus";
import { routes } from "../../../routes";
import { useUserPage } from "./UserPage";

export function UserDetailView(props: {user: User}) {
	const history = useHistory();
	const alert = useAlert();
	const context = useUserPage();

	const { user } = props;
	const userId = user.id;

	const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
	const [disabled, setDisabled] = useState(false);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	const [firstName, setFirstName] = useState(user.firstName);
	const [lastName, setLastName] = useState(user.lastName);
	const email = user.email;

	const [isOffice, setIsOffice] = useState(user.claims.some((c) => c === UserClaim.Office));
	const [isDriver, setIsDriver] = useState(user.claims.some((c) => c === UserClaim.Driver));
	const [isAdmin, setIsAdmin] = useState(user.claims.some((c) => c === UserClaim.Admin));
	const disableIsOffice = isAdmin;



	useEffect(() => {
		if (isAdmin) {
			setIsOffice(false);
		}
	}, [isAdmin]);

	const onUpdateUser = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const claims: UserClaim[] = [];

		if (isOffice) {
			claims.push(UserClaim.Office);
		}

		if (isDriver) {
			claims.push(UserClaim.Driver);
		}

		if (isAdmin) {
			claims.push(UserClaim.Admin);
		}

		setDisabled(true);
		const result = await BusinessService.updateUser({ userId, firstName, lastName, claims });
		setDisabled(false);

		if (result.success) {
			alert.success(`${result.data.fullName} has been updated`);
			context.updateUser(result.data);
		} else if (result.validation) {
			setValidationErrors(result.errors);
			const nonFieldErrors = validationError.getErrorsExcludingFields(result.errors, ["FirstName", "LastName", "Claims"]);
			if (nonFieldErrors.length > 0) {
				alert.validation({ errors: nonFieldErrors });
			}
		} else {
			alert.serverError(result);
		}
	};

    const onChangeStatus = async (disabled: boolean) => {
        setDisabled(true);
        const result = await BusinessService.updateUserStatus({userId, disabled});
        setDisabled(false);

        if(result.success){
            context.updateUser(result.data);
            alert.success(`${result.data.fullName} is ${disabled ? "disabled" : "active"}`);
        } else if(result.validation){
            alert.validation(result);
        } else {
            alert.serverError(result);
        }
    }

    const onDelete = async () => {
        setDisabled(true);
        const result = await BusinessService.deleteUser(userId);
        setDisabled(false);

        if(result.success){
            alert.success(`User deleted`);
			context.deleteUser(user);
            history.replace(routes.business.userPage);
        } else if(result.validation){
            alert.validation(result);
        } else {
            alert.serverError(result);
        }
    }

	const statusActionButton = () => {
		if (!user) {
			return null;
		}

		if (user.status === UserStatus.Invited) {
			return <RedButton disabled={disabled} onClick={() => setShowDeleteDialog(true)}>Delete</RedButton>;
		}

		if (user.status === UserStatus.Disabled) {
			return (
				<Button variant="contained" color="primary" disabled={disabled} onClick={() => onChangeStatus(false)}>
					Enable
				</Button>
			);
		}

		return <RedButton disabled={disabled} onClick={() => onChangeStatus(true)}>Disable</RedButton>;
	};

	if (!user) {
		return <OverlayLoadingScreen />;
	}

	return (
		<Grid container>
			<Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<DialogContentText>
						This will delete the user and cancel the invitation that was sent to them. Are you sure you want to delete {user.fullName}?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowDeleteDialog(false)} color="primary">
						No
					</Button>
					<Button onClick={onDelete} color="primary">
						Yes
					</Button>
				</DialogActions>
			</Dialog>
			<Grid item xs={12}>
				<Typography variant="h5">
					<IconButton onClick={history.goBack}>
						<ArrowBackIcon />
					</IconButton>
					{user.fullName}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader
						style={{ paddingBottom: 0 }}
						title={
							<Grid container>
								<Grid item>
									<Typography variant="subtitle2" style={{ fontSize: "0.90rem" }}>
										Status - {Enum.print(UserStatus, user.status)}
									</Typography>
								</Grid>
								<div style={{ flex: "1" }} />
								<Grid item>
									<div style={{ margin: 5 }}>{statusActionButton()}</div>
								</Grid>
							</Grid>
						}
					/>
					<form onSubmit={onUpdateUser}>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item lg={4} xs={12}>
									<TextField
										required
										fullWidth
										disabled={disabled}
										label="First Name"
										value={firstName}
										onChange={(e) => setFirstName(e.target.value)}
										error={validationError.isFieldInError(validationErrors, "FirstName")}
										helperText={validationError.getFieldError(validationErrors, "FirstName")}
									/>
								</Grid>
								<Grid item lg={4} xs={12}>
									<TextField
										required
										fullWidth
										disabled={disabled}
										label="Last Name"
										value={lastName}
										onChange={(e) => setLastName(e.target.value)}
										error={validationError.isFieldInError(validationErrors, "LastName")}
										helperText={validationError.getFieldError(validationErrors, "LastName")}
									/>
								</Grid>
								<Grid item lg={4} xs={12}>
									<TextField required fullWidth disabled={true} label="Email" type="email" value={email} />
								</Grid>
								<Grid item lg={4} xs={12}>
									<FormControl component="fieldset">
										<FormLabel component="legend">Roles</FormLabel>
										{validationError.isFieldInError(validationErrors, "Claims") && (
											<FormHelperText error>{validationError.getFieldError(validationErrors, "Claims")}</FormHelperText>
										)}
										<Tooltip title={<Typography>Can manage customers and orders</Typography>}>
											<FormControlLabel
												control={
													<Checkbox
														color="primary"
														checked={isOffice}
														disabled={disableIsOffice || disabled}
														onChange={(e) => setIsOffice(e.target.checked)}
													/>
												}
												label="Office"
											/>
										</Tooltip>
										<Tooltip title={<Typography>Is available as a driver that can handle orders</Typography>}>
											<FormControlLabel
												control={<Checkbox color="primary" checked={isDriver} disabled={disabled} onChange={(e) => setIsDriver(e.target.checked)} />}
												label="Driver"
											/>
										</Tooltip>
										<Tooltip title={<Typography>Has access to everything including users management and billing</Typography>}>
											<FormControlLabel
												control={<Checkbox color="primary" checked={isAdmin} disabled={disabled} onChange={(e) => setIsAdmin(e.target.checked)} />}
												label="Administrator"
											/>
										</Tooltip>
									</FormControl>
								</Grid>
							</Grid>
						</CardContent>
						<CardActions>
							<Button type="submit">Save</Button>
						</CardActions>
					</form>
				</Card>
			</Grid>
		</Grid>
	);
}
