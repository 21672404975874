import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import OverlayLoadingScreen from "../../components/OverlayLoadingScreen";
import { ServerErrorView } from "../../components/ServerErrorView";
import { Invoice } from "../../entities/billing/Invoice";
import { routes } from "../../routes";
import { InvoiceService } from "../../services/billing/InvoiceService";
import { ServerError } from "../../services/server/WebClient";
import { InvoiceDetailPage } from "./InvoiceDetailPage";
import { InvoiceListPage } from "./InvoiceListPage";

export function InvoicePage() {
    const params = useParams<{ invoiceId?: string }>();
    const { invoiceId } = params;

    const [serverError, setServerError] = React.useState<ServerError>();
    const [invoice, setInvoice] = React.useState<Invoice>();

    React.useEffect(() => {
        async function fetch(id: string) {
            const result = await InvoiceService.get(id);
            if (result.success) {
                setInvoice(result.data);
            } else {
                setServerError(result);
            }
        }

        if (invoiceId) {
            fetch(invoiceId);
        } else {
            setInvoice(undefined);
        }
    }, [invoiceId]);

    if (serverError) {
        return <ServerErrorView serverError={serverError} />;
    }

    if (invoiceId && !invoice) {
        return <OverlayLoadingScreen />;
    }

     return (<Switch>
			<Route path={routes.app.invoicePage} exact>
				<InvoiceListPage />
			</Route>
			{invoice && (
				<Route path={routes.app.invoiceDetailPage} exact>
					<InvoiceDetailPage selectedInvoice={invoice} onUpdate={setInvoice} />
				</Route>
			)}
            <Redirect to={routes.app.invoicePage}/>
		</Switch>)
}
