import { Box, Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { routes } from "../../../routes";
import { PageTitle } from "../../../components/PageTitle";
import { CancelTicketDialog } from "./CancelTicketDialog";
import { BackButton } from "../../../components/BackButton";
import { AssignedTicketDriver } from "./AssignedTicketDriver";
import { TicketDueDate } from "./TicketDueDate";
import { GridGrow } from "../../../components/GridGrow";
import { CustomerAddressNavigationLink } from "../../../components/address/CustomerAddressNavigationLink";
import { TicketInstructions } from "./TicketInstructions";
import { DriverTicket } from "../../../entities/routing/DriverTicket";
import { RestrictToRole } from "../../../components/RestrictToRole";
import { useOfflineTickets } from "../../../providers/OfflineTicketProvider";
import { useAlert } from "../../../hooks/useAlert";
import { DriverTicketService } from "../../../services/routing/DriverTicketService";
import { TicketDeliveryDate } from "./TicketDeliveryDate";
import { OnlineProfileService } from "../../../services/business/OnlineProfileService";
import { TicketInvoiceDetail } from "./TicketInvoiceDetail";
import { EmailInvoiceConfirmation } from "./EmailInvoiceConfirmation";
import { TicketNumber } from "./TicketNumber";
import { TicketTags } from "./TicketTags";
import { printDate } from "../../../utility/PrintDate";
import { TankFillDetails } from "./TankFillDetails";
import { TicketQuoteDetails } from "./TicketQuoteDetails";
import { TicketNotes } from "./TicketNotes";

interface TicketDetailContext {
	ticket: DriverTicket;
	disabled: boolean;
	updateTicket: (ticket: DriverTicket) => void;
	setDisabled: (disabled: boolean) => void;
}
const TicketDetailReactContext = React.createContext<TicketDetailContext>({} as TicketDetailContext);

export function useTicketDetail() {
	return React.useContext(TicketDetailReactContext);
}

export function DriverTicketDetailView(props: { ticket: DriverTicket; updateTicket: (ticket: DriverTicket) => void; onBack: () => void; fullScreen?: boolean }) {
	const { ticket, fullScreen } = props;
	const alert = useAlert();
	const history = useHistory();
	const offlineTicketContext = useOfflineTickets();

	const [showCancelationConfirm, setShowCancelationConfirm] = useState(false);
	const [showEmailInvoice, setShowEmailInvoice] = useState(false);
	const [disabled, setDisabled] = useState(false);

	const [canSendEmail, setCanSendEmail] = React.useState(false);

	const customerHasEmail = ticket.customer.email != null && ticket.customer.email.trim() !== "";
	const allowSendEmail = ticket.customer.allowSendEmail;
	const canCompleteTicket =
		ticket.timeOfCompletion == null && (ticket.quoteItems.length === 0 || ticket.quoteItems.every((item) => item.quantity !== null && item.quantity > 0));

	useEffect(() => {
		async function checkIfEmailIsSetup() {
			const result = await OnlineProfileService.get();
			if (result.success && result.data != null) {
				setCanSendEmail(true);
			}
		}

		if (customerHasEmail && allowSendEmail) {
			checkIfEmailIsSetup();
		}
	}, [allowSendEmail, customerHasEmail]);

	const updateTicket = (ticket: DriverTicket) => {
		props.updateTicket(ticket);
		offlineTicketContext.update(ticket);
	};

	const onCompleteTicket = () => {
		if (canSendEmail) {
			setShowEmailInvoice(true);
		} else {
			completeTicket(null);
		}
	};

	const confirmEmailInvoice = (email: string | null) => {
		setShowEmailInvoice(false);
		completeTicket(email);
	};

	const completeTicket = async (emailInvoiceTo: string | null) => {
		setDisabled(true);
		const result = await DriverTicketService.completeTicket({
			emailInvoiceTo,
			driverTicketId: ticket.id,
		});

		setDisabled(false);
		if (result.success) {
			alert.success("Ticket completed");
			updateTicket(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const reopenTicket = async () => {
		setDisabled(true);
		const result = await DriverTicketService.reopenTicket(ticket.id);
		setDisabled(false);
		if (result.success) {
			alert.success("Ticket re-opened");
			updateTicket(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	}

	const tenSecondsAgo = new Date(new Date().getTime() - 1000 * 10);

	return (
		<TicketDetailReactContext.Provider value={{ ticket, updateTicket, disabled, setDisabled }}>
			<Box padding={fullScreen ? 1 : undefined}>
				<Grid container justify="center">
					<PageTitle title={`Ticket for ${ticket.customer.name}`} />
					<CancelTicketDialog ticket={ticket} open={showCancelationConfirm} onClose={() => setShowCancelationConfirm(false)} onTicketCanceled={history.goBack} />
					<Grid item xl={fullScreen ? undefined : 6} lg={fullScreen ? undefined : 8} md={fullScreen ? undefined : 10} xs={12}>
						<Grid container alignItems="center">
							<Grid item>
								<BackButton onClick={props.onBack} />
							</Grid>
							<Grid item>
								<Typography variant="h5">
									Ticket for{" "}
									<Link to={routes.app.resolve.customerDetailPage(ticket.customer.id)} style={{ cursor: "pointer", fontWeight: 500, color: "unset" }}>
										{ticket.customer.name}
									</Link>
								</Typography>
							</Grid>
							<GridGrow />
							{ticket.timeOfCompletion == null && (
								<Grid item>
									<Grid container spacing={2} wrap="nowrap">
										<Grid item>
											<Button color="secondary" variant="contained" onClick={() => setShowCancelationConfirm(true)} disabled={disabled}>
												Cancel Ticket
											</Button>
										</Grid>
										<RestrictToRole admin>
											<Grid item>
												<Button color="primary" variant="contained" onClick={onCompleteTicket} disabled={!canCompleteTicket || disabled}>
													Complete Ticket
												</Button>
											</Grid>
											<EmailInvoiceConfirmation open={showEmailInvoice} onClose={() => setShowEmailInvoice(false)} onConfirm={confirmEmailInvoice} />
										</RestrictToRole>
									</Grid>
								</Grid>
							)}
							{ticket.timeOfCompletion != null && !ticket.invoice && ticket.timeOfCompletion < tenSecondsAgo && (
								<Grid item>
									<Button color="secondary" variant="outlined" onClick={reopenTicket}>
										Re-Open Ticket
									</Button>
								</Grid>
							)}
						</Grid>
						<Card>
							<Grid container justify="flex-end">
								<Grid item>
									<Typography variant="overline" color="textSecondary" style={{ paddingRight: 5 }}>
										Created: {printDate.shortDate(ticket.created)}
									</Typography>
								</Grid>
							</Grid>
							<CardContent style={{ paddingTop: 0 }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item lg={3} md={4} sm={3} xs={6}>
												<AssignedTicketDriver />
											</Grid>

											<Grid item lg={3} md={4} sm={3} xs={6}>
												<TicketDueDate />
											</Grid>
											<Grid item lg={3} md={4} sm={3} xs={6}>
												<TicketDeliveryDate />
											</Grid>
											<Grid item lg={3} md={4} sm={3} xs={6}>
												<TicketNumber />
											</Grid>
										</Grid>
									</Grid>

									<Grid item sm={6} xs={12}>
										<TicketInstructions />
									</Grid>
									<Grid item sm={6} xs={12}>
										<Typography variant="h6" color="textSecondary" gutterBottom>
											Location
										</Typography>
										<Typography variant="body1">
											<CustomerAddressNavigationLink address={ticket.location} />
										</Typography>
									</Grid>

									<Grid item sm={6} xs={12}>
										<TicketTags />
									</Grid>
									{ticket.invoice && (
										<Grid item sm={6} xs={12}>
											<TicketInvoiceDetail invoice={ticket.invoice} />
										</Grid>
									)}
									<Grid item xs={12}>
										<TicketNotes />
									</Grid>
									<Grid item xs={12}>
										<TankFillDetails />
									</Grid>
									<Grid item xs={12}>
										<TicketQuoteDetails />
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</TicketDetailReactContext.Provider>
	);
}
