import { deserializeOtodataDevice, deserializeOtodataDevices, deserializeTankLevels } from "../../entities/routing/OtodataTankMonitor"
import { deserializeCustomerTankMonitor, deserializeCustomerTankMonitors } from "../../entities/routing/TankMonitor"
import { apiServer } from "../server/Setting"
import { WebClient } from "../server/WebClient"

interface GetOtodataTankLevels {
    id: number;
    startDate: Date;
    endDate: Date;
}

interface CreateOtodataDispatchProTankMonitor {
    otodataDeviceId: number;
    tankId: string;
}

export const TankMonitorService = {
    getCustomerMonitors: () => WebClient.Get(`${apiServer}/api/tank-monitors/dispatch-pro`, deserializeCustomerTankMonitors),
    getOtodataDevices: () => WebClient.Get(`${apiServer}/api/tank-monitors/otodata`, deserializeOtodataDevices),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getOtodataDevice: (id: number) => WebClient.Get(`${apiServer}/api/tank-monitors/otodata/${id}`, (model: { otodataDevice: any, customerTankMonitor: any }) => {
        return {
            otodataDevice: deserializeOtodataDevice(model.otodataDevice),
            customerTankMonitor: model.customerTankMonitor ? deserializeCustomerTankMonitor(model.customerTankMonitor): null
        }
    }),
    getOtodataTankLevels: (request: GetOtodataTankLevels) => WebClient.Post.Unvalidated(`${apiServer}/api/tank-monitors/otodata/levels`, request, deserializeTankLevels),
    createOtodataDispatchProTankMonitor: (request: CreateOtodataDispatchProTankMonitor) => WebClient.Post.Validated(`${apiServer}/api/tank-monitors/otodata`, request, deserializeCustomerTankMonitor),
    unassignOtodataDispatchProTankMonitor: (id: string) => WebClient.Delete(`${apiServer}/api/tank-monitors/otodata/${id}`),
}