import { apiServer } from "../services/server/Setting";
import { WebClient } from "../services/server/WebClient";
export const PasswordSuggester = async () => {
    let password = "";
    while(password.length < 10){
        const response = await WebClient.Get<{suggestion: string}>(`${apiServer}/password-suggestion`);
        if(response.success){
            password = response.data.suggestion;
        }
        if(password.length < 10){
            await sleep(500);
        }
    }
    return password;
}

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }