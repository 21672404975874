import { BankAccountPaymentMethod, CreditCardPaymentMethod, PaymentMethod } from "../../entities/billing/PaymentMethod";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

export interface QualpayPaymentConfiguration {
    merchantId: number;
    transientKey: string;
}

interface AddQualpayCreditCardRequest {
    cardId: string;
    zipCode: string;
    firstName: string;
    lastName: string;
    customerId: string;
}

interface AddQualpayBankAccountRequest {
    customerId: string;
    accountNumber: string;
    routingNumber: string;
    accountType: string;
    firstName: string;
    lastName: string;
}

interface AddDispatchProCreditCardRequest {
    customerId: string;
    token: string;
    isDefault: boolean;
}

export interface SurchargeConfiguration {
    maxPercentage: number;
    defaultPercentage: number;
    enabled: boolean;
}


export const PaymentMethodService = {
    getByCustomer: (customerId: string) => WebClient.Get<PaymentMethod[]>(`${apiServer}/api/payment-methods/customer/${customerId}`),
    setDefault: (paymentMethodId: string) => WebClient.Put.Validated<PaymentMethod[]>(`${apiServer}/api/payment-methods/${paymentMethodId}/default`, null),
    remove: (paymentMethodId: string) => WebClient.Delete(`${apiServer}/api/payment-methods/${paymentMethodId}`),
    getSurchargeConfiguration: () => WebClient.Get<SurchargeConfiguration>(`${apiServer}/api/payment-methods/credit-card/surcharge`),
    getQualpayPaymentConfiguration: () => WebClient.Get<QualpayPaymentConfiguration>(`${apiServer}/api/payment-methods/configuration/qualpay`),
    addQualpayCreditCard: (request: AddQualpayCreditCardRequest) => WebClient.Post.Validated<CreditCardPaymentMethod>(`${apiServer}/api/payment-methods/credit-card/qualpay`, request),
    addQualpayBankAccount: (request: AddQualpayBankAccountRequest) => WebClient.Post.Validated<BankAccountPaymentMethod>(`${apiServer}/api/payment-methods/bank-account/qualpay`, request),
    addDispatchProCreditCard: (request: AddDispatchProCreditCardRequest) => WebClient.Post.Validated<CreditCardPaymentMethod>(`${apiServer}/api/payment-methods/credit-card/dispatch-pro`, request),
};