import React from "react";
import { Route, Switch } from "react-router-dom";
import { CompanyProfilePage } from "../pages/business/company-profile/CompanyProfilePage";
import { InspectionListDetailPage } from "../pages/business/inspections/InspectionListDetailPage";
import { IntegrationsPage } from "../pages/business/integrations/IntegrationsPage";
import { LedgerAccountPage } from "../pages/business/ledger-accounts/LedgerAccountPage";
import { PaymentTermsPage } from "../pages/business/payment-terms/PaymentTermsPage";
import { TaxRulePage } from "../pages/business/tax-rules/TaxRulePage";
import { TruckDetailPage } from "../pages/business/trucks/TruckDetailPage";
import { TrucksPage } from "../pages/business/trucks/TrucksPage";
import { UserPage } from "../pages/business/users/UserPage";
import { routes } from "../routes";
import { OnlineProfilePage } from "../pages/business/online-profile/OnlineProfilePage";
import { ProductListingDetailPage } from "../pages/products/ProductListingDetailPage";
import { DocumentsPage } from "../pages/business/documents/DocumentsPage";
import { ProductLineDetailPage } from "../pages/products/ProductLineDetailPage";
import { ProductCatalogDetailPage } from "../pages/products/ProductCatalogDetailPage";

export function BusinessRoutes() {
	return (
		<Switch>
			<Route exact path={routes.business.companyProfilePage}>
				<CompanyProfilePage />
			</Route>
			<Route exact path={routes.business.documentsPage}>
				<DocumentsPage />
			</Route>
			<Route exact path={routes.business.onlineProfilePage}>
				<OnlineProfilePage />
			</Route>
			<Route exact path={[routes.business.userPage, routes.business.userDetailPage]}>
				<UserPage />
			</Route>
			<Route exact path={routes.business.productCatalogPage}>
				<ProductCatalogDetailPage />
			</Route>
			<Route exact path={routes.business.productLinePage}>
				<ProductLineDetailPage />
			</Route>
			<Route exact path={routes.business.productListingPage}>
				<ProductListingDetailPage />
			</Route>
			<Route exact path={[routes.business.generalLedgerAccountsPage, routes.business.generalLedgerAccountDetailPage]}>
				<LedgerAccountPage />
			</Route>
			<Route exact path={[routes.business.taxRulesPage, routes.business.taxRuleDetailPage]}>
				<TaxRulePage />
			</Route>
			<Route exact path={[routes.business.paymentTermsPage, routes.business.paymentTermsDetailPage]}>
				<PaymentTermsPage />
			</Route>
			<Route exact path={routes.business.trucksPage}>
				<TrucksPage />
			</Route>
			<Route exact path={routes.business.truckDetailPage}>
				<TruckDetailPage />
			</Route>
			<Route exact path={routes.business.inspectionListDetailPage}>
				<InspectionListDetailPage />
			</Route>
			<Route exact path={[routes.business.integrationPage, ...routes.business.integrationDetailPages()]}>
				<IntegrationsPage />
			</Route>
			<Route> Missing Route</Route>
		</Switch>
	);
}
