import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { IntegrationType } from "../entities/auth/IntegrationConfiguration";
import { JwtUser } from "../entities/auth/JwtUser";
import { useUser } from "../hooks/useUser";
import { routes } from "../routes";
import { addScript, addStylesheet, removeElement } from "../utility/addScript";

const AppUserContext = React.createContext<JwtUser>({} as JwtUser);

export function useAppUser() {
	return React.useContext(AppUserContext);
}

export function AppUserProvider(props: PropsWithChildren<{}>) {
	const [user] = useUser();
	const location = useLocation();
	if (!user) return <Redirect to={`${routes.public.signInPage}?redirect=${encodeURI(location.pathname + location.search)}`} />;
	return (
		<AppUserContext.Provider value={user}>
			<IntegrationProvider enabledIntegrations={user.enabledIntegrations}>{props.children}</IntegrationProvider>
		</AppUserContext.Provider>
	);
}

function IntegrationProvider(props: PropsWithChildren<{ enabledIntegrations: IntegrationType[] }>) {
	const appUser = useAppUser();
	const { paymentProcessor } = appUser;
	const loadQualpayScript = useMemo(() => paymentProcessor === "qualpay", [paymentProcessor]);
	const loadDispatchProPaymentsScript = useMemo(() => paymentProcessor === "dispatchpro", [paymentProcessor]);

	useEffect(() => {
		if(!loadQualpayScript) return;

		const scriptId = "qualpay-script";
		addScript({id: scriptId, src: "https://app.qualpay.com/hosted/embedded/js/qp-embedded-sdk.min.js"});
		const styleId = "qualpay-style";
		addStylesheet({id: styleId, src: "https://app.qualpay.com/hosted/embedded/css/qp-embedded.css"});

		return () => {
			removeElement(scriptId);
			removeElement(styleId);
		};
	}, [loadQualpayScript]);

	useEffect(() => {
		if(!loadDispatchProPaymentsScript) return;

		const scriptId = "finix-script";
		addScript({id: scriptId, src: `https://js.finix.com/v/1/finix.js` });

		return () => {
			removeElement(scriptId);
		};
	}, [loadDispatchProPaymentsScript]);

	return props.children as React.ReactElement;
}
