import { deserializeDriverTicket, deserializeDriverTickets, DriverTicket, SerializedDriverTicket } from "../../entities/routing/DriverTicket";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";
import { OfflineRequestManager } from "../../providers/OfflineRequestManager";
import { OptionalValue, toOptional } from "../../utility/OptionalValue";
import { deserializeDriver, Driver, SerializedDriver } from "../../entities/routing/Driver";
import { GeocodedAddress } from "../../entities/customer/GeocodedAddress";
import { NodaTime } from "../../utility/NodaTime";

interface UpdateTicketRequest {
	driverTicketId: string;
	notes?: string;
	timeOfDelivery?: Date | null;
	dueBy?: Date | null;
	ticketNumber?: string | null;
}

type SerializedUpdateTicketRequest = Omit<UpdateTicketRequest, "dueAfter" | "dueBy" | "ticketNumber" | "timeOfDelivery"> & {
	timeOfDelivery?: OptionalValue<Date | null>;
	dueBy?: OptionalValue<Date | null>;
	ticketNumber?: OptionalValue<string | null>;
};

function serializeUpdateTicketRequest(request: UpdateTicketRequest): SerializedUpdateTicketRequest {
	return {
		...request,
		timeOfDelivery: toOptional(request.timeOfDelivery),
		dueBy: toOptional(request.dueBy),
		ticketNumber: toOptional(request.ticketNumber),
	};
}

export interface DeliverTicketRequest {
	driverTicketId: string;
	quoteItems: { quoteItemId: string; quantity: number | null; price: number, contractId: string | null }[];
}

export interface CompleteTicketRequest {
	driverTicketId: string;
	emailInvoiceTo: string | null;
}

interface UpdateTicketRouteSequenceRequest {
	driverId: string;
	orderedDriverTicketIds: string[];
}

interface SearchTicketsRequest {
	customerCode?: string | null;
	ticketNumber?: string | null;
	status?: string | null;
	driverId?: string | null;
	page?: number | null;
	pageSize?: number | null;
	sortBy?: string | null;
	sortOrder?: string | null;
}

export interface TicketSearchResult {
	results: DriverTicket[];
	totalCount: number;
	page: number;
	pageSize: number;
	totalPages: number;
}

type SerializedTicketSearchResult = Omit<TicketSearchResult, "results"> & {
	results: SerializedDriverTicket[];
};

function deserializeTicketSearchResult(result: SerializedTicketSearchResult): TicketSearchResult {
	return {
		...result,
		results: deserializeDriverTickets(result.results),
	};
}

interface GeoSearchTicketsRequest {
	northEastLatitude: number;
	northEastLongitude: number;
	southWestLatitude: number;
	southWestLongitude: number;
}

export interface TicketLocation {
	ticketId: string;
	latitude: number;
	longitude: number;
	daysOld: number;
}

export interface PendingTickets {
	driverTickets: { driver: Driver; tickets: DriverTicket[] }[];
	unassignedTickets: DriverTicket[];
}

type SerializedPendingTickets = Omit<PendingTickets, "driverTickets" | "unassignedTickets"> & {
	driverTickets: { driver: SerializedDriver; tickets: SerializedDriverTicket[] }[];
	unassignedTickets: SerializedDriverTicket[];
};

function deserializePendingTickets(result: SerializedPendingTickets): PendingTickets {
	return {
		driverTickets: result.driverTickets.map((dt) => ({
			driver: deserializeDriver(dt.driver),
			tickets: deserializeDriverTickets(dt.tickets),
		})),
		unassignedTickets: deserializeDriverTickets(result.unassignedTickets),
	};
}

export interface DriverRouteOptimization {
	driverId: string;
	canBeOptimized: boolean;
	timeSaved: string;
	distanceSaved: string;
	proposedTicketOrder: string[];
}

export interface TicketTaxRule {
	taxRuleId: number | null;
	taxAccountId: number;
	taxAccountName: string;
	name: string;
	ratePercent: number | null;
	fixedAmount: number | null;
}

export interface CreateTankFillRequest {
	tankId: string;
	productListingId: string;
	productName: string;
	quantity: number | null;
	price: number;
	reportedPercentage: number | null;
	taxRules: TicketTaxRule[];
}

export interface CreateTicketProductRequest {
	productListingId: string;
	productName: string;
	quantity: number | null;
	price: number;
	taxRules: TicketTaxRule[];
}

export interface CreateDriverTicketRequest {
	customerId: string;
	location: GeocodedAddress;
	driverId?: string | null;
	dueDate?: Date | null;
	instructions?: string | null;
	tankFills: CreateTankFillRequest[];
	products: CreateTicketProductRequest[];
	tagIds: number[];
	priceLockContractIds: string[];
}

type SerializedCreateDriverTicketRequest = Omit<CreateDriverTicketRequest, "dueDate"> & {
	dueDate?: string | null;
};

function serializeCreateDriverTicketRequest(request: CreateDriverTicketRequest): SerializedCreateDriverTicketRequest {
	return {
		...request,
		dueDate: request.dueDate ? NodaTime.serializeToLocalDate(request.dueDate) : null,
	};
}

interface UpdateTankFillRequest {
	driverTicketId: string;
	quoteItemId: string;
	reportedTankPercentage?: OptionalValue<number | null>;
	quantity?: OptionalValue<number | null>;
	unitPrice?: OptionalValue<number | null>;
	taxRules?: TicketTaxRule[];
}

interface UpdateQuoteProductRequest {
	driverTicketId: string;
	quoteItemId: string;
	quantity?: OptionalValue<number | null>;
	unitPrice?: OptionalValue<number | null>;
	taxRules?: TicketTaxRule[];
}

interface AddTankFillRequest {
	driverTicketId: string;
	tankId: string;
	productListingId: string;
}

interface AddQuoteProductRequest {
	driverTicketId: string;
	productListingId: string;
}

interface RecalculateQuoteItemTaxRequest {
	driverTicketId: string;
	quoteItemId: string;
}

export interface AddTicketNoteRequest {
	customerId: string;
	ticketId: string;
	note: string;
}

export interface Waypoint {
	description: string;
	ticketId: string | null;
	stopNumber: number;
	address: GeocodedAddress;
}

export interface OptimizeRouteRequest {
	driverId: string;
	origin: Waypoint;
	destination: Waypoint;
	stops: Waypoint[];
}

export interface TicketAgeStats {
	quickestCompletionDays: number;
	medianCompletionDays: number;
	slowestCompletionDays: number;
	averageCompletionDays: number;
}

export const DriverTicketService = {
	getOpenTickets: () => WebClient.Get(`${apiServer}/api/driver-ticket/open`, deserializeDriverTickets),
	getRecentCustomerTickets: (customerId: string) => WebClient.Get(`${apiServer}/api/driver-ticket/customer/${customerId}/recent`, deserializeDriverTickets),
	getPendingTickets: () => WebClient.Get(`${apiServer}/api/driver-ticket/pending-review`, deserializePendingTickets),
	get: (driverTicketId: string) => WebClient.Get(`${apiServer}/api/driver-ticket/${driverTicketId}`, deserializeDriverTicket),
	getMany: (ticketIds: string[]) => WebClient.Post.Unvalidated(`${apiServer}/api/driver-ticket/load`, { ticketIds }, deserializeDriverTickets),
	getTicketAgeStats: () => WebClient.Get<TicketAgeStats>(`${apiServer}/api/driver-ticket/age-stats`),
	search: (request: SearchTicketsRequest) => WebClient.Post.Validated(`${apiServer}/api/driver-ticket/search`, request, deserializeTicketSearchResult),
	geoSearch: (request: GeoSearchTicketsRequest) => WebClient.Post.Unvalidated<TicketLocation[]>(`${apiServer}/api/driver-ticket/geo-search`, request),
	create: (request: CreateDriverTicketRequest) =>
		WebClient.Post.Validated(`${apiServer}/api/driver-ticket`, serializeCreateDriverTicketRequest(request), deserializeDriverTicket),
	update: (request: UpdateTicketRequest) => WebClient.Put.Validated(`${apiServer}/api/driver-ticket`, serializeUpdateTicketRequest(request), deserializeDriverTicket),
	addTankFill: (request: AddTankFillRequest) =>
		WebClient.Post.Validated(`${apiServer}/api/driver-ticket/${request.driverTicketId}/tank-fill`, request, deserializeDriverTicket),
	addQuoteProduct: (request: AddQuoteProductRequest) =>
		WebClient.Post.Validated(`${apiServer}/api/driver-ticket/${request.driverTicketId}/quote-product`, request, deserializeDriverTicket),
	updateTankFill: (request: UpdateTankFillRequest) =>
		WebClient.Put.Validated(`${apiServer}/api/driver-ticket/${request.driverTicketId}/tank-fill`, request, deserializeDriverTicket),
	updateQuoteProduct: (request: UpdateQuoteProductRequest) =>
		WebClient.Put.Validated(`${apiServer}/api/driver-ticket/${request.driverTicketId}/quote-product`, request, deserializeDriverTicket),
	recalculateQuoteItemTax: (request: RecalculateQuoteItemTaxRequest) =>
		WebClient.Put.Validated(`${apiServer}/api/driver-ticket/${request.driverTicketId}/quote-item/${request.quoteItemId}/recalculate-tax`, {}, deserializeDriverTicket),
	removeQuoteItem: (request: { driverTicketId: string; quoteItemId: string }) =>
		WebClient.Delete(`${apiServer}/api/driver-ticket/${request.driverTicketId}/quote-item/${request.quoteItemId}`, deserializeDriverTicket),
	updateRouteSequence: (request: UpdateTicketRouteSequenceRequest) =>
		WebClient.Put.Unvalidated(`${apiServer}/api/driver-ticket/route-sequence`, request, deserializeDriverTickets),
	getRouteOptimization: (request: OptimizeRouteRequest) => WebClient.Post.Validated<DriverRouteOptimization>(`${apiServer}/api/driver-ticket/optimize-route`, request),
	deliverTicket: (request: DeliverTicketRequest) => WebClient.Post.Validated(`${apiServer}/api/driver-ticket/deliver`, request, deserializeDriverTicket),
	completeTicket: (request: CompleteTicketRequest) => WebClient.Put.Validated(`${apiServer}/api/driver-ticket/complete-ticket`, request, deserializeDriverTicket),
	reopenTicket: (driverTicketId: string) => WebClient.Put.Validated(`${apiServer}/api/driver-ticket/re-open-ticket`, { driverTicketId }, deserializeDriverTicket),
	assignDriver: (driverTicketId: string, driverId: string) =>
		WebClient.Post.Unvalidated(`${apiServer}/api/driver-ticket/${driverTicketId}/driver/${driverId}`, {}, deserializeDriverTicket),
	unassignDriver: (driverTicketId: string) => WebClient.Put.Unvalidated(`${apiServer}/api/driver-ticket/${driverTicketId}/driver/unassign`, {}, deserializeDriverTicket),
	cancelTicket: (driverTicketId: string) => WebClient.Put.Validated<null>(`${apiServer}/api/driver-ticket/${driverTicketId}/cancel`, {}),
	addTicketNote: (request: AddTicketNoteRequest) => WebClient.Post.Validated(`${apiServer}/api/driver-ticket/${request.ticketId}/note`, request, deserializeDriverTicket),
	offline: {
		updateRouteSequence: (offlineManager: OfflineRequestManager, request: UpdateTicketRouteSequenceRequest) => {
			offlineManager.addRequestToQueue({
				id: `ReorderTicketRoute-${request.driverId}`,
				method: "PUT",
				name: "Reorder Route",
				payload: request,
				url: `${apiServer}/api/driver-ticket/route-sequence`,
			});
		},
		deliverTicket: (offlineManager: OfflineRequestManager, request: DeliverTicketRequest) => {
			offlineManager.addRequestToQueue({
				id: `DeliverTicket-${request.driverTicketId}`,
				method: "POST",
				name: "Deliver Ticket",
				payload: request,
				url: `${apiServer}/api/driver-ticket/deliver`,
			});
		},
		addTicketNote: (offlineManager: OfflineRequestManager, request: AddTicketNoteRequest) => {
			offlineManager.addRequestToQueue({
				id: `AddTicketNote-${request.ticketId}-${Math.floor(Math.random() * 10000) + 1}`,
				method: "POST",
				name: "Add Ticket Note",
				payload: request,
				url: `${apiServer}/api/driver-ticket/${request.ticketId}/note`,
			});
		},
	},
};
