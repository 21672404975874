export enum UserClaim {
    Driver = 1,
    Office = 2,
    Admin = 10,
    Super_Admin = 100
}

export function getUserClaim(id: number){
    switch(id){
        case UserClaim.Driver: return UserClaim.Driver;
        case UserClaim.Office: return UserClaim.Office;
        case UserClaim.Admin: return UserClaim.Admin;
        case UserClaim.Super_Admin: return UserClaim.Super_Admin;
        default: return undefined;
    }
}