import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	Grid,
	IconButton,
	MenuItem,
	Paper,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TextField,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import CenteredLoadingSpinner from "../../components/CenteredLoadingSpinner";
import { GridGrow } from "../../components/GridGrow";
import { HighlightedText } from "../../components/HighlightedText";
import OverlayLoadingScreen from "../../components/OverlayLoadingScreen";
import { PageTitle } from "../../components/PageTitle";
import { SearchTextField } from "../../components/SearchTextField";
import { ServerErrorView } from "../../components/ServerErrorView";
import { SortableTableHeader, TableHeader, TableSortOrder } from "../../components/table/SortableTableHeader";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { TagBubble } from "../../components/TagBubble";
import { formatAddress } from "../../entities/customer/GeocodedAddress";
import { Tag } from "../../entities/customer/Tag";
import { DriverTicket } from "../../entities/routing/DriverTicket";
import { useAlert } from "../../hooks/useAlert";
import { useDrivers } from "../../providers/DriverProvider";
import { routes } from "../../routes";
import { TagService } from "../../services/customer/TagService";
import { ServerError } from "../../services/server/WebClient";
import { DriverTicketService } from "../../services/routing/DriverTicketService";
import { orderBy, SortDirection } from "../../utility/orderBy";
import { useLocalStorage } from "../../utility/useLocalStorage";
import { TicketStatus } from "./common/TicketStatus";
import { ClientLink } from "../../components/ClientLink";

function TicketContainsTag(ticket: DriverTicket, tag: Tag): boolean {
	return ticket.tags.some((t) => t.id === tag.id);
}

function TicketContainsAllTags(ticket: DriverTicket, tags: Tag[]) {
	if (tags.length === 0) return true;

	if (ticket.tags.length === 0) return false;

	return tags.every((tag) => TicketContainsTag(ticket, tag));
}

function SearchExclude(searchTextLower: string, ticket: DriverTicket): boolean {
	if (!searchTextLower) {
		return true;
	}
	if (ticket.customer.name.toLowerCase().includes(searchTextLower)) {
		return false;
	}
	if (ticket.customer.customerCode?.toLowerCase().includes(searchTextLower)) {
		return false;
	}
	if (ticket.quoteItems.some((i) => i.tank && formatAddress(i.tank.address).toLowerCase().includes(searchTextLower))) {
		return false;
	}
	if (ticket.instructions.toLowerCase().includes(searchTextLower)) {
		return false;
	}
	if (ticket.driverName && ticket.driverName.toLowerCase().includes(searchTextLower)) {
		return false;
	}
	if (ticket.lastFillDate && ticket.lastFillDate.toLowerCase().includes(searchTextLower)) {
		return false;
	}
	if (ticket.tags.some((tag) => tag.text.toLowerCase().includes(searchTextLower))) {
		return false;
	}
	return true;
}

function SearchMatches(searchTextLower: string, ticket: DriverTicket): boolean {
	if (!searchTextLower) {
		return true;
	}
	if (ticket.customer.name.toLowerCase().includes(searchTextLower)) {
		return true;
	}
	if (ticket.customer.customerCode?.toLowerCase().includes(searchTextLower)) {
		return true;
	}
	if (ticket.quoteItems.some((i) => i.tank && formatAddress(i.tank.address).toLowerCase().includes(searchTextLower))) {
		return true;
	}
	if (ticket.instructions.toLowerCase().includes(searchTextLower)) {
		return true;
	}
	if (ticket.driverName && ticket.driverName.toLowerCase().includes(searchTextLower)) {
		return true;
	}
	if (ticket.lastFillDate && ticket.lastFillDate.toLowerCase().includes(searchTextLower)) {
		return true;
	}
	if (ticket.tags.some((tag) => tag.text.toLowerCase().includes(searchTextLower))) {
		return true;
	}
	return false;
}

function TicketSortOrder(sortOrder: TableSortOrder, sortProperty: string): (a: DriverTicket, b: DriverTicket) => number {
	const sortDirection: SortDirection = sortOrder === "asc" ? "Ascending" : "Descending";
	switch (sortProperty) {
		case "CustomerName":
			return orderBy.string((t) => t.customer.name, sortDirection);
		case "Address":
			return orderBy.string((t) => t.location.city, sortDirection);
		case "Urgency":
			return orderBy.number((t) => t.urgencyScore, sortDirection);
		case "LastFill":
			return orderBy.optional.date((t) => (t.lastFillDate ? new Date(t.lastFillDate) : null), sortDirection, "optionalEnd");
		default:
			return () => 0;
	}
}

export function OpenDriverTicketsListPage() {
	const headerRef = React.useRef<HTMLDivElement>(null);
	const bottomMargin = 10;
	const headerHeight = headerRef.current ? headerRef.current.offsetHeight + headerRef.current.offsetTop : 250;

	const [openTickets, setOpenTickets] = useState<DriverTicket[]>();
	const [serverError, setServerError] = useState<ServerError>();
	const [sortProperty, setSortProperty] = useLocalStorage<string>("open-ticket-list-view-sortProperty", "Address");
	const [sortOrder, setSortOrder] = useLocalStorage<TableSortOrder>("open-ticket-list-view-sortOrder", "asc");
	const [includeSearchText, setIncludeSearchText] = useLocalStorage<string>("open-ticket-list-view-searchText", "");
	const [excludeSearchText, setExcludeSearchText] = useLocalStorage<string>("open-ticket-list-view-excludeSearchText", "");
	const [tagFilters, setTagFilters] = useLocalStorage<Tag[]>("open-ticket-list-view-tags", []);
	const [showAssigned, setShowAssigned] = useLocalStorage<boolean>("open-ticket-list-view-showAssigned", true);
	const [selectedTicketIds, setSelectedTicketIds] = useLocalStorage<string[]>("open-ticket-list-view-selectedTicketIds", []);
	const [showAssignDialog, setShowAssignDialog] = useState(false);
	const [showTagDialog, setShowTagDialog] = useState(false);

	const tickets = useMemo(() => openTickets ?? [], [openTickets]);
	const assignedTickets = useMemo(() => (showAssigned ? tickets : tickets.filter((t) => t.driverId == null)), [showAssigned, tickets]);
	const includeSearchTextLower = useMemo(() => includeSearchText.toLowerCase(), [includeSearchText]);
	const excludeSearchTextLower = useMemo(() => excludeSearchText.toLowerCase(), [excludeSearchText]);
	const filteredTickets = useMemo(
		() => assignedTickets.filter((customer) => SearchExclude(excludeSearchTextLower, customer)).filter((customer) => SearchMatches(includeSearchTextLower, customer)),
		[assignedTickets, excludeSearchTextLower, includeSearchTextLower]
	);
	const tagFilteredTickets = useMemo(
		() => (tagFilters.length > 0 ? filteredTickets.filter((c) => TicketContainsAllTags(c, tagFilters)) : filteredTickets),
		[filteredTickets, tagFilters]
	);
	const sortedTickets = useMemo(() => tagFilteredTickets.sort(TicketSortOrder(sortOrder, sortProperty)), [tagFilteredTickets, sortOrder, sortProperty]);
	const selectedTickets = useMemo(() => tickets.filter((t) => selectedTicketIds.includes(t.id)), [tickets, selectedTicketIds]);

	useEffect(() => {
		async function loadTickets() {
			const result = await DriverTicketService.getOpenTickets();
			if (result.success) {
				setOpenTickets(result.data);
			} else {
				setServerError(result);
			}
		}
		loadTickets();
	}, []);

	const addTagFilter = (tag: Tag) => {
		if (!tagFilters.find((t) => t.id === tag.id)) {
			setTagFilters([...tagFilters, tag]);
		}
	};

	const removeTagFilter = (tag: Tag) => {
		setTagFilters(tagFilters.filter((t) => t.id !== tag.id));
	};

	const sortTable = (property: string, order: TableSortOrder) => {
		setSortProperty(property);
		setSortOrder(order);
	};

	const onTicketSelected = (ticket: DriverTicket) => {
		if (selectedTicketIds.includes(ticket.id)) {
			setSelectedTicketIds(selectedTicketIds.filter((id) => id !== ticket.id));
		} else {
			setSelectedTicketIds([...selectedTicketIds, ticket.id]);
		}
	};

	const onAssign = (tickets: DriverTicket[]) => {
		setShowAssignDialog(false);
		setOpenTickets((t) => (t ? t.map((t) => tickets.find((t2) => t2.id === t.id) ?? t) : tickets));
		setSelectedTicketIds([]);
	};

	const onTag = (tickets: DriverTicket[]) => {
		setShowTagDialog(false);
		setOpenTickets((t) => (t ? t.map((t) => tickets.find((t2) => t2.id === t.id) ?? t) : tickets));
	};

	const printTickets = () => {
		const printWindow = window.open("", "Print Open Tickets", "width=800,height=600");
		if (printWindow) {
			printWindow.document.write(`
			<html>
				<head>
					<title>Open Tickets</title>
					<style>
						@page {
							size: auto;
							margin: 5mm;
						}
						body {
							margin: 5mm;
						}
						table {
							width: 100%;
							border-collapse: collapse;
							page-break-inside: auto;
						}
						tr {
							page-break-inside: avoid;
							page-break-after: auto;
						}
						table, th, td {
							border: 1px solid black;
						}
						th, td {
							padding: 5px;
						}
						th {
							text-align: left;
						}
						thead {
							display: table-header-group;
						}
					</style>
				</head>
				<body>
					<h4>Open Tickets</h4>`);
			printWindow.document.write("<table>");
			printWindow.document.write("<thead><tr><th>Customer</th><th>Address</th><th>Instructions</th><th>Last Fill</th><th>Urgency</th><th>Tags</th></tr></thead>");
			sortedTickets.forEach((t) => {
				printWindow.document.write("<tr>");
				printWindow.document.write(`<td>${t.customer.name}</td>`);
				printWindow.document.write(`<td>${formatAddress(t.location)}</td>`);
				printWindow.document.write(`<td>${t.instructions}</td>`);
				printWindow.document.write(`<td>${t.lastFillDate ?? ""}</td>`);
				printWindow.document.write(`<td>${t.urgencyScore}</td>`);
				printWindow.document.write(`<td>${t.tags.map((t) => t.text).join(", ")}</td>`);
				printWindow.document.write("</tr>");
			});
			printWindow.document.write("</table>");
			printWindow.document.write("</body></html>");
			printWindow.document.close();
			printWindow.focus();
			printWindow.print();
			printWindow.close();
		}
	};

	if (serverError) {
		return <ServerErrorView serverError={serverError} />;
	}

	if (!openTickets) {
		return <OverlayLoadingScreen />;
	}

	return (
		<>
			<PageTitle title="Open Driver Tickets" />
			<AssignDialog open={showAssignDialog} tickets={selectedTickets} onClose={() => setShowAssignDialog(false)} onAssign={onAssign} />
			<TagDialog open={showTagDialog} tickets={selectedTickets} onClose={() => setShowTagDialog(false)} onTag={onTag} />
			<div ref={headerRef}>
				<Grid container spacing={2} alignItems="flex-end">
					<Grid item>
						<Typography variant="h2">Open Tickets</Typography>
						<Typography variant="subtitle1">
							{sortedTickets.length} showing {selectedTickets.length} selected
						</Typography>
					</Grid>
					<Grid item>
						<Button variant="outlined" color="primary" disabled={selectedTickets.length === 0} onClick={() => setShowAssignDialog(true)}>
							Driver
						</Button>
					</Grid>
					<Grid item>
						<Button variant="outlined" color="primary" onClick={() => setShowTagDialog(true)} disabled={selectedTickets.length === 0}>
							Tag
						</Button>
					</Grid>
					<Grid item>
						<Button variant="outlined" color="secondary" disabled={selectedTickets.length === 0} onClick={() => setSelectedTicketIds([])}>
							Clear Selection
						</Button>
					</Grid>
					<Grid item>
						<Button variant="outlined" disabled={tickets.length === 0} onClick={printTickets}>
							Print
						</Button>
					</Grid>
					<GridGrow />
					{tagFilters.length > 0 && (
						<Grid item>
							<Grid container spacing={1}>
								{tagFilters.map((tag) => (
									<Grid item key={tag.id}>
										<TagBubble tag={tag} onDelete={removeTagFilter} />
									</Grid>
								))}
							</Grid>
						</Grid>
					)}
					<Grid item>
						<Box mb={1}>
							<SearchTextField value={includeSearchText} onChange={setIncludeSearchText} autoFocus placeholder="Search:Include" />
						</Box>
						<SearchTextField value={excludeSearchText} onChange={setExcludeSearchText} autoFocus placeholder="Search:Exclude" />
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={<Switch checked={showAssigned} onChange={(e) => setShowAssigned(e.target.checked)} color="primary" />}
							label="Show Assigned"
						/>
					</Grid>
				</Grid>
			</div>
			<Paper elevation={3}>
				<TableContainer style={{ maxHeight: `calc(100vh - ${headerHeight + bottomMargin}px` }}>
					<Table stickyHeader size="small">
						<SortableTableHeader order={sortOrder} orderBy={sortProperty}>
							<TableHeader />
							<TableHeader property="CustomerName" onSort={sortTable}>
								Customer
							</TableHeader>
							<TableHeader property="Address" onSort={sortTable}>
								Address
							</TableHeader>
							<TableHeader>Instructions</TableHeader>
							<TableHeader>Status</TableHeader>
							<TableHeader property="LastFill" onSort={sortTable}>
								Last Fill
							</TableHeader>
							<TableHeader property="Urgency" onSort={sortTable}>
								Urgency
							</TableHeader>
							<TableHeader align="center">Tags</TableHeader>
						</SortableTableHeader>
						<TableBody>
							{sortedTickets.map((ticket, i) => (
								<TicketRow
									key={i}
									ticket={ticket}
									searchText={includeSearchText}
									selectedTicketIds={selectedTicketIds}
									tagFilters={tagFilters}
									onTicketSelected={onTicketSelected}
									addTagFilter={addTagFilter}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</>
	);
}

type TicketRowProps = {
	ticket: DriverTicket;
	searchText: string;
	selectedTicketIds: string[];
	tagFilters: Tag[];
	onTicketSelected: (ticket: DriverTicket) => void;
	addTagFilter: (tag: Tag) => void;
};

function TicketRow(props: TicketRowProps) {
	const { ticket, searchText, selectedTicketIds, tagFilters } = props;
	const history = useHistory();
	const ticketId = ticket.id;

	const onTicketDetailSelected = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		history.push(routes.app.resolve.ticketDetailPage(ticketId));
	};

	const rowSelected = useMemo(() => selectedTicketIds.includes(ticketId), [selectedTicketIds, ticketId]);

	return (
		<TableRow hover selected={rowSelected} onClick={() => props.onTicketSelected(ticket)}>
			<TableCell>
				<IconButton onClick={onTicketDetailSelected} size="small">
					<ListAltIcon />
				</IconButton>
			</TableCell>
			<TableCell>
				<HighlightedText query={searchText}>{ticket.customer.name}</HighlightedText>
			</TableCell>
			<TableCell>
				<HighlightedText query={searchText}>{formatAddress(ticket.location)}</HighlightedText>
			</TableCell>
			<TableCell>
				<HighlightedText query={searchText}>{ticket.instructions}</HighlightedText>
			</TableCell>
			<TableCell>
				<TicketStatus ticket={ticket} />
			</TableCell>
			<TableCell>{ticket.lastFillDate}</TableCell>
			<TableCell>{ticket.urgencyScore}</TableCell>
			<TableCell align="center">
				<Grid container spacing={1} justify="center">
					{ticket.tags.map((tag) => (
						<Grid item key={tag.id}>
							<TagBubble tag={tag} onClick={props.addTagFilter} selected={tagFilters.some((t) => t.id === tag.id)} />
						</Grid>
					))}
				</Grid>
			</TableCell>
		</TableRow>
	);
}

function TagDialog(props: { open: boolean; tickets: DriverTicket[]; onClose: () => void; onTag: (tickets: DriverTicket[]) => void }) {
	const { open, tickets } = props;
	const alert = useAlert();

	const [tags, setTags] = useState<Tag[]>([]);
	const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
	const [inProgressMessage, setInProgressMessage] = useState<string | null>(null);
	const [inProgressErrorMessage, setInProgressErrorMessage] = useState<string | null>(null);

	useEffect(() => {
		async function loadTags() {
			const result = await TagService.getAll();
			if (result.success) {
				setTags(result.data.filter((t) => t.type === "Driver Ticket"));
			} else {
				alert.serverError(result);
			}
		}
		loadTags();
	}, [alert]);

	const setTicketTags = async () => {
		let count = 1;
		let errorCount = 0;
		const updatedTickets: DriverTicket[] = [];
		for (const ticket of tickets) {
			setInProgressMessage(`Assigning tags to ${count} of ${tickets.length}...`);
			const result = await TagService.setTicketTags({ driverTicketId: ticket.id, tagIds: selectedTagIds });
			if (result.success) {
				updatedTickets.push(result.data);
			} else {
				errorCount++;
				setInProgressErrorMessage(`Failed to assign tags to ${errorCount} of ${tickets.length}.`);
			}
			count++;
		}
		setInProgressMessage(null);
		setInProgressErrorMessage(null);
		setSelectedTagIds([]);
		props.onTag(updatedTickets);
		alert.success(`Assigned ${tickets.length - errorCount} tag${tickets.length === 1 ? "" : "s"}.`);
	};

	const handleTagSelected = (tag: Tag) => {
		if (selectedTagIds.includes(tag.id)) {
			setSelectedTagIds(selectedTagIds.filter((id) => id !== tag.id));
		} else {
			setSelectedTagIds([...selectedTagIds, tag.id]);
		}
	};

	const onCancel = () => {
		setSelectedTagIds([]);
		props.onClose();
	};

	return (
		<Dialog open={open} onClose={inProgressMessage !== null ? undefined : onCancel} fullWidth maxWidth="sm">
			<DialogTitle>Set Tags</DialogTitle>
			<DialogContent>
				{inProgressMessage && (
					<>
						<CenteredLoadingSpinner />
						<DialogContentText>{inProgressMessage}</DialogContentText>
						{inProgressErrorMessage && <Typography color="error">{inProgressErrorMessage}</Typography>}
					</>
				)}
				{!inProgressMessage && tags.length > 0 && (
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="body1">
								All tags selected below will override the tags on your {tickets.length} ticket{tickets.length > 1 && "s"}.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={1}>
								{tags.map((tag) => (
									<Grid item key={tag.id}>
										<TagBubble tag={tag} onClick={handleTagSelected} selected={selectedTagIds.includes(tag.id)} />
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				)}
				{tags.length === 0 && tickets.length > 0 && (
					<DialogContentText>
						No tags have been created. Tags can be created in the Tags section on the{" "}
						<ClientLink to={routes.app.resolve.ticketDetailPage(tickets[0].id)}>ticket detail page</ClientLink>.
					</DialogContentText>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel} color="secondary" variant="outlined" disabled={inProgressMessage !== null}>
					Cancel
				</Button>
				<GridGrow />
				<Button onClick={setTicketTags} color="secondary" variant="contained" disabled={inProgressMessage !== null || selectedTagIds.length > 0}>
					Remove Tags
				</Button>
				<Button onClick={setTicketTags} color="primary" variant="contained" disabled={selectedTagIds.length === 0}>
					Set Tags
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function AssignDialog(props: { open: boolean; tickets: DriverTicket[]; onClose: () => void; onAssign: (tickets: DriverTicket[]) => void }) {
	const { open, tickets } = props;
	const { drivers } = useDrivers();
	const alert = useAlert();

	const [selectedDriverId, setSelectedDriverId] = useState<string>("none");
	const [inProgressMessage, setInProgressMessage] = useState<string | null>(null);
	const [inProgressErrorMessage, setInProgressErrorMessage] = useState<string | null>(null);
	const selectedDriver = useMemo(() => drivers.find((d) => d.id === selectedDriverId), [drivers, selectedDriverId]);

	const assign = async () => {
		if (!selectedDriver) {
			return;
		}
		let count = 1;
		let errorCount = 0;
		const updatedTickets: DriverTicket[] = [];
		for (const ticket of tickets) {
			setInProgressMessage(`Assigning ticket ${count} of ${tickets.length}...`);
			const result = await DriverTicketService.assignDriver(ticket.id, selectedDriver.id);
			if (result.success) {
				updatedTickets.push(result.data);
				setSelectedDriverId("none");
			} else {
				errorCount++;
				setInProgressErrorMessage(`Failed to assign ticket ${errorCount} of ${tickets.length}.`);
			}
			count++;
		}
		setInProgressMessage(null);
		setInProgressErrorMessage(null);
		props.onAssign(updatedTickets);
		alert.success(`Assigned ${tickets.length - errorCount} ticket${tickets.length === 1 ? "" : "s"} to ${selectedDriver.name}.`);
	};

	const unassign = async () => {
		let count = 1;
		let errorCount = 0;
		const updatedTickets: DriverTicket[] = [];
		for (const ticket of tickets) {
			setInProgressMessage(`Unassigning ticket ${count} of ${tickets.length}...`);
			const result = await DriverTicketService.unassignDriver(ticket.id);
			if (result.success) {
				updatedTickets.push(result.data);
			} else {
				errorCount++;
				setInProgressErrorMessage(`Failed to unassign ticket ${errorCount} of ${tickets.length}.`);
			}
			count++;
		}
		setInProgressMessage(null);
		setInProgressErrorMessage(null);
		props.onAssign(updatedTickets);
		alert.success(`Unassigned ${tickets.length - errorCount} ticket${tickets.length === 1 ? "" : "s"}.`);
	};

	const onCancel = () => {
		setSelectedDriverId("none");
		props.onClose();
	};

	return (
		<Dialog open={open} onClose={inProgressMessage !== null ? undefined : onCancel} maxWidth="xs" fullWidth>
			<DialogTitle>Assign Tickets</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Assign {tickets.length} ticket{tickets.length === 1 ? "" : "s"} to
				</DialogContentText>
				{inProgressMessage && (
					<>
						<CenteredLoadingSpinner />
						<DialogContentText>{inProgressMessage}</DialogContentText>
						{inProgressErrorMessage && <Typography color="error">{inProgressErrorMessage}</Typography>}
					</>
				)}
				<TextField select label="Driver" value={selectedDriverId} fullWidth variant="outlined" onChange={(e) => setSelectedDriverId(e.target.value)}>
					<MenuItem value="none">None</MenuItem>
					{drivers.map((driver) => (
						<MenuItem key={driver.id} value={driver.id}>
							{driver.name}
						</MenuItem>
					))}
				</TextField>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel} color="secondary" variant="outlined" disabled={inProgressMessage !== null}>
					Cancel
				</Button>
				<GridGrow />
				<Button onClick={unassign} color="secondary" variant="contained" disabled={inProgressMessage !== null || selectedDriver != null}>
					Unassign
				</Button>
				<Button onClick={assign} color="primary" variant="contained" disabled={!selectedDriver || inProgressMessage !== null}>
					Assign
				</Button>
			</DialogActions>
		</Dialog>
	);
}
