import { AxiosRequestConfig } from "axios";
import Auth from "../../authorization/Auth";
import { Modify } from "../../utility/modifyType";
import AuthService from "../auth/AuthService";
import { CustomAxios } from "./CustomAxios";
import { FieldValidationError } from "./ServerValidationError";
import fileDownload from "js-file-download";

type BaseServerResponse = {
	success: false;
	validation: false;
	error: false;
};
export type ServerError = Modify<
	BaseServerResponse,
	{
		message: string;
		statusCode: number;
		error: true;
		offline: boolean;
	}
>;

export type ValidationError = Modify<
	BaseServerResponse,
	{
		errors: FieldValidationError[];
		validation: true;
	}
>;

export type SuccessResult<T> = Modify<
	BaseServerResponse,
	{
		data: T;
		success: true;
	}
>;

type DefaultServerResult<OutputModel> = ServerError | SuccessResult<OutputModel>;
export type FetchResult<OutputModel> = ServerError | SuccessResult<OutputModel>;
export type ValidatedServerResult<OutputModel> = ServerError | SuccessResult<OutputModel> | ValidationError;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DeserializeModel<T> = (serverModel: any) => T;

export const createServerError = (message: string, statusCode: number): ServerError => ({
	message,
	statusCode,
	error: true,
	success: false,
	validation: false,
	offline: false,
});

const runFetch = async <OutputModel>(url: string, config: AxiosRequestConfig, deserialize?: DeserializeModel<OutputModel>) => {
	const firstResult = await attemptRequest(url, config, deserialize);

	if (!firstResult.error || firstResult.statusCode !== 401) {
		return firstResult;
	}

	const jwt = await AuthService.attemptTokenRefreshAndSet();
	if (!jwt) {
		AuthService.signOut(true);
		return firstResult;
	}

	// retry the request
	return await attemptRequest(url, config, deserialize);
};

const attemptRequest = async <OutputModel>(url: string, config: AxiosRequestConfig, deserialize?: DeserializeModel<OutputModel>) => {
	config = addJwtHeader(config);
	config = await attemptRefreshExpiredJwtHeader(config);
	const axiosClient = CustomAxios.create();
	const axiosResponse = await axiosClient(url, config);
	const result = axiosResponse.data as ValidatedServerResult<OutputModel>;
	if (result.success && deserialize) {
		result.data = deserialize(result.data);
	}
	return result;
};

const attemptRefreshExpiredJwtHeader = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
	const user = Auth.getJwtUser();

	if (user && user.expiration.getTime() < new Date().getTime()) {
		console.log("Auth Token Expired. Attempting refresh.");
		const success = await AuthService.attemptTokenRefreshAndSet();
		if (success) {
			return addJwtHeader(config);
		}
	}

	return config;
};

const addJwtHeader = (config: AxiosRequestConfig): AxiosRequestConfig => {
	if (!config.headers) {
		config.headers = {};
	}
	const token = Auth.getSavedToken();
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
};

export const WebClient = {
	Get: <OutputModel>(url: string, deserialize?: DeserializeModel<OutputModel>) =>
		runFetch<OutputModel>(url, { method: "get", withCredentials: true }, deserialize) as Promise<FetchResult<OutputModel>>,
	Put: {
		Validated: <OutputModel>(url: string, data: unknown, deserialize?: DeserializeModel<OutputModel>): Promise<ValidatedServerResult<OutputModel>> =>
			runFetch<OutputModel>(url, { method: "put", data, withCredentials: true }, deserialize) as Promise<ValidatedServerResult<OutputModel>>,
		Unvalidated: <OutputModel>(url: string, data: unknown, deserialize?: DeserializeModel<OutputModel>) =>
			runFetch<OutputModel>(url, { method: "put", data, withCredentials: true }, deserialize) as Promise<DefaultServerResult<OutputModel>>,
	},
	Post: {
		Validated: <OutputModel>(url: string, data: unknown, deserialize?: DeserializeModel<OutputModel>) =>
			runFetch<OutputModel>(url, { method: "post", data, withCredentials: true }, deserialize) as Promise<ValidatedServerResult<OutputModel>>,
		Unvalidated: <OutputModel>(url: string, data: unknown, deserialize?: DeserializeModel<OutputModel>) =>
			runFetch<OutputModel>(url, { method: "post", data, withCredentials: true }, deserialize) as Promise<DefaultServerResult<OutputModel>>,
	},
	Delete: <OutputModel>(url: string, deserialize?: DeserializeModel<OutputModel>) =>
		runFetch<OutputModel>(url, { method: "delete", withCredentials: true }, deserialize) as Promise<ValidatedServerResult<OutputModel>>,
	Download: {
		Get: (url: string, fileName: string, mimeType?: string) =>
			runFetch<Blob>(url, { method: "get", responseType: "blob", withCredentials: true }).then((result) => {
				if (result.success) {
					fileDownload(result.data, fileName, mimeType);
					return { ...result, data: true };
				}
				return result;
			}) as Promise<DefaultServerResult<boolean>>,
		Post: (url: string, data: unknown, fileName: string, mimeType?: string) =>
		runFetch<Blob>(url, { method: "post", data, responseType: "blob", withCredentials: true }).then((result) => {
			if (result.success) {
				fileDownload(result.data, fileName, mimeType);
				return { ...result, data: true };
			}
			return result;
		}) as Promise<DefaultServerResult<boolean>>
	},
};
