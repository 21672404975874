import React, { useState } from "react";
import { ReportService, TankLastFillReport } from "../../services/report/ReportService";
import { useAlert } from "../../hooks/useAlert";
import { Grid, Card, CardHeader, CardContent, Button, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { Table } from "react-super-responsive-table";
import { BackButton } from "../../components/BackButton";
import { ClientLink } from "../../components/ClientLink";
import { PageTitle } from "../../components/PageTitle";
import { routes } from "../../routes";
import { GridGrow } from "../../components/GridGrow";
import { Price } from "../../components/Price";
import { printDate } from "../../utility/PrintDate";

export function TankLastFillReportPage() {
	const alert = useAlert();

	const [disabled, setDisabled] = useState(false);
	const [report, setReport] = useState<TankLastFillReport>();

	const generateReport = async () => {
		setDisabled(true);
		const result = await ReportService.tankLastFillReport({ numberOfFillsToInclude: 2 });
		setDisabled(false);
		if (result.success) {
			setReport(result.data);
		} else {
			alert.serverError(result);
		}
	};

	const downloadCsv = () => {
		if (report) {
			const csv =
				`Customer,Tank,LastFillDate1,DaysSince1,LastFillDate2,DaysSince2\n` +
				report.items
					.map(
						(item) =>
							`${item.customerName.replace(",", "")},${item.tankDescription.replace(",", "")},${
								item.fills.length === 0 ? "" : item.fills[0].date.toLocaleDateString()
							},${item.fills.length === 0 ? "" : item.fills[0].daysSinceLastFill},${
								item.fills.length < 2 ? "" : item.fills[1].date.toLocaleDateString()
							},${item.fills.length < 2 ? "" : item.fills[1].daysSinceLastFill}\n`
					)
					.join("");
			const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
			const url = URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.setAttribute("href", url);
			link.setAttribute("download", "tank-last-fill-report.csv");
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	return (
		<Grid container justify="center" spacing={2}>
			<PageTitle title="Tank Last Fill Report" />
			<Grid item lg={6} md={8} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="Tank Last Fill Report" subheader="See the last fill date for active customer tank" />
						</Grid>
					</Grid>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item>
								<Button variant="contained" color="primary" onClick={generateReport} disabled={disabled}>
									Generate Report
								</Button>
							</Grid>
							<GridGrow />
							<Grid item>
								<Button variant="outlined" color="primary" onClick={downloadCsv} disabled={disabled || !report}>
									Download CSV
								</Button>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			{report && (
				<Grid item sm={10} xs={12}>
					<Card className="print-table" id="tank-alert-report">
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Customer</TableCell>
									<TableCell>Tank</TableCell>
									<TableCell width={300}>Fills</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{report.items.map((item, i) => (
									<TableRow key={i}>
										<TableCell>
											<ClientLink newTab to={routes.app.resolve.customerDetailPage(item.customerId)}>
												{item.customerName}
											</ClientLink>
										</TableCell>
										<TableCell>
											<ClientLink newTab to={routes.app.resolve.propaneTankDetailPage(item.tankId)}>
												{item.tankDescription}
											</ClientLink>
										</TableCell>
										<TableCell width={300}>
											{item.fills.length === 0 && "Never"}
											{item.fills.map((fill, i) => (
												<div key={i} style={{ marginBottom: "5px", display: "block"}}>
													{printDate.shortDate(fill.date)} ({fill.daysSinceLastFill} day{fill.daysSinceLastFill < 2 ? "" : "s"}) @{" "}<Price value={fill.price} />
												</div>
											))}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Card>
				</Grid>
			)}
		</Grid>
	);
}
