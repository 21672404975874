import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { ServerError } from "../services/server/WebClient";

export function ServerErrorView(props: { serverError: ServerError }) {
	const { serverError } = props;
	return (
		<Grid container direction="column" justify="center" alignItems="center">
			<Grid item>
				<Grid container justify="center" alignItems="center">
					<Grid item>
						<Typography variant="h1">
							{serverError.statusCode} - {serverError.message}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
