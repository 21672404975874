import { Card, CardHeader, Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { BackButton } from "../../../components/BackButton";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { PageTitle } from "../../../components/PageTitle";
import { ServerErrorView } from "../../../components/ServerErrorView";
import { Truck } from "../../../entities/routing/Truck";
import { TruckService } from "../../../services/routing/TruckService";
import { ServerError } from "../../../services/server/WebClient";
import { TruckDetailCard } from "./TruckDetailCard";
import { VehicleInspection } from "../../../entities/routing/VehicleInspection";
import CenteredLoadingSpinner from "../../../components/CenteredLoadingSpinner";
import { TruckInspectionsReportModel, VehicleInspectionService } from "../../../services/routing/TruckInspectionService";
import { useAlert } from "../../../hooks/useAlert";

export function TruckDetailPage() {
	const params = useParams<{ truckId: string }>();
	const { truckId } = params;

	const [truck, setTruck] = React.useState<Truck>();
	const [serverError, setServerError] = React.useState<ServerError>();

	React.useEffect(() => {
		async function fetchTruck() {
			const result = await TruckService.get(truckId);
			if (result.success) {
				setTruck(result.data);
			} else {
				setServerError(result);
			}
		}
		fetchTruck();
	}, [truckId]);

	if (serverError) {
		return <ServerErrorView serverError={serverError} />;
	}

	if (!truck) {
		return <OverlayLoadingScreen />;
	}

	return <TruckDetailView truck={truck} onUpdate={setTruck} />;
}

function TruckDetailView(props: { truck: Truck; onUpdate: (truck: Truck) => void }) {
	const { truck } = props;
	return (
		<Grid container spacing={2}>
			<PageTitle title={`Truck - ${truck.name}`} />
			<Grid item xs={12}>
				<Grid container alignItems="center">
					<Grid item>
						<BackButton />
					</Grid>
					<Grid item>
						<Typography variant="h5">Truck: {truck.name}</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item lg={6} xs={12}>
				<TruckDetailCard truck={truck} onUpdate={props.onUpdate} />
			</Grid>
			<Grid item lg={6} xs={12}>
				<TruckInspectionsCard truck={truck} />
			</Grid>
		</Grid>
	);
}

function TruckInspectionsCard(props: { truck: Truck }) {
	const { truck } = props;
	const truckId = truck.id;

	const alert = useAlert();

	const [report, setReport] = React.useState<TruckInspectionsReportModel>();
	const [page, setPage] = React.useState(0);
	const pageSize= 10;

	React.useEffect(() => {
		async function fetchInspections() {
			const result = await VehicleInspectionService.getCompleted({ truckId, page, pageSize });
			if (result.success) {
				setReport(result.data);
			} else {
				alert.serverError(result);
			}
		}
		fetchInspections();
	}, [truckId, page, pageSize, alert]);

	if (!report) {
		return (
			<Card>
				<CardHeader title="Completed Inspections" />
				<CenteredLoadingSpinner />
			</Card>
		);
	}

	return (
		<Card>
			<CardHeader title="Completed Inspections" />
			<Table size="small">
				<TableHead>
					<TableRow>
						<TablePagination
							colSpan={5}
							count={report.count}
							rowsPerPage={pageSize}
							rowsPerPageOptions={[]}
							page={page}
							onChangePage={(_, page) => setPage(page)}
						/>
					</TableRow>
					<TableRow>
						<TableCell>Date</TableCell>
						<TableCell>List</TableCell>
						<TableCell>Inspected By</TableCell>
						<TableCell>Issues</TableCell>
						<TableCell>Notes</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{report.inspections.map((inspection) => (
						<InspectionTableRow key={inspection.id} inspection={inspection} />
					))}
				</TableBody>
			</Table>
		</Card>
	);
}

function InspectionTableRow(props: { inspection: VehicleInspection }) {
	const { inspection } = props;
	const alert = useAlert();
	const [downloadInProgress, setDownloadInProgress] = React.useState(false);

	const issues = useMemo(() => {
		if (inspection.satisfactory) {
			return "None";
		}
		const issues = inspection.items.filter((item) => !item.satisfactory);
		return issues.map((item) => item.name).join(", ");
	}, [inspection]);

	const downloadInspection = async () => {
		setDownloadInProgress(true);
		const result = await VehicleInspectionService.downloadReport(inspection);
		if(result.error) {
			alert.serverError(result);
		}
		setDownloadInProgress(false);
	};

	return (
		<TableRow hover style={{ cursor: "pointer" }} onClick={downloadInspection}>
			<TableCell>{inspection.createdAt.toLocaleString()}</TableCell>
			<TableCell>{inspection.inspectionListName}</TableCell>
			<TableCell>{inspection.inspectedBy}</TableCell>
			<TableCell>{issues}</TableCell>
			<TableCell>{inspection.notes == null && inspection.satisfactory ? "No" : "Yes"}</TableCell>
			{downloadInProgress && <OverlayLoadingScreen />}
		</TableRow>
	);
}
